import PropTypes from 'prop-types'
import {Token} from "../../config/tokens";
import React, {useCallback} from "react";
import {useTokenImage} from "../../hooks/useTokenImage";
import {Logo} from "../Logo";
import {useFormattedBalance} from "../../hooks/useTokenBalance";
import {useCurrency} from "../../hooks/Tokens";

export const TokenListItem = ({token, selectedToken, onTokenSelect=()=>{}} ) => {
    const tokenImage = useTokenImage(token)
    const balance = useFormattedBalance(token)
    const _token = useCurrency(token.address)

    const _onTokenSelect = useCallback(() => {
        onTokenSelect(token)
    }, [token, onTokenSelect])

    return (
        <li key={token.address} onClick={_onTokenSelect} className={selectedToken?.symbol === token.symbol && "active"}>
            <div className='inrBx'>
                <Logo image={tokenImage} />
                <p>{token.symbol} <span>{token.name}</span></p>
            </div>
            <strong>
                { balance && balance?.toSignificant(4)}
                { !balance && "0.0000"}
            </strong>
        </li>
    )
}

TokenListItem.propTypes = {
    token: PropTypes.instanceOf(Token),
    selectedToken: PropTypes.instanceOf(Token),
    onTokenSelect: PropTypes.func
}