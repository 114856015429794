import {interval} from "../config/pools";
export const calculateApr = (value, ogAPR, lockPeriod) => {
    const perYearYield = value * ogAPR / 100
    const perDayYield = perYearYield / 360
    const lockDays = lockPeriod / interval
    const totalGeneratedOG = perDayYield * lockDays

    return {
        totalOG: totalGeneratedOG,
        perDayYield: perDayYield
    }
}