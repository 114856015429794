import PropTypes from "prop-types";
import SelectToken from "./SelectToken";
import React, {useCallback, useMemo, useState} from "react";
import {CDropdown, CDropdownToggle} from "@coreui/react";
import {Logo} from "../Logo";
import {Token} from "../../config/tokens";
import {useTokenImage} from "../../hooks/useTokenImage";

export const TokenSelect = ({token, onTokenSelect=()=>{}}) => {
    const [visibleSlot, setVisibleSlot] = useState(false)
    const imageURL = useTokenImage(useMemo(() => token, [token]))

    const _onTokenSelect = useCallback((token) => {
        setVisibleSlot(false)
        onTokenSelect(token)
    }, [onTokenSelect, setVisibleSlot])

    return (
        <>
            <CDropdown>
                <CDropdownToggle onClick={() => setVisibleSlot(!visibleSlot)} color="secondary">
                    <Logo image={imageURL} />
                    { token && token?.symbol }
                    { !token && 'Select Token' }
                </CDropdownToggle>
            </CDropdown>
            <SelectToken onTokenSelect={_onTokenSelect} visibleSlot={visibleSlot} setVisibleSlot={setVisibleSlot} selectedToken={token} />
        </>
    )
}

TokenSelect.propTypes = {
    token: PropTypes.instanceOf(Token),
    onTokenSelect: PropTypes.func
}