import React from "react";
import { TokenSelect } from "./TokenSelect";
import useCurrencyBalance from "../../hooks/useTokenBalance";
import { formatCurrencyAmount } from "../../utils/formatCurrencyBalance";

export const InputPanel = ({
    currency,
    onUserInput,
    value,
    label,
    onCurrencySelect,
    showMaxButton,
    onMax,
    disabled = false
}) => {
    const balance = useCurrencyBalance(currency ?? undefined)

    return (
        <>
            <div className="main-box">
                <div className="form-group">
                    <label htmlFor="">{label}</label>
                    {
                        disabled ? <span>
                            {Number(parseFloat(value.replaceAll(",", "")).toFixed(4))}
                        </span> : <input disabled={disabled} onInput={(e) => { onUserInput(e.target.value) }} value={value.replaceAll(",", "")} type="number" placeholder='0' />
                    }
                </div>
                <div className="token-balance" style={{ display: "flex", flexDirection: "column", alignItems: "end", marginRight: `${onMax && "5px"}` }}>
                    {
                        balance && <div style={{ textAlign: "right", marginBottom: "10px" }} className="tokenDiv">
                            Balance: <span onClick={showMaxButton ? onMax : null} style={onMax ? { color: "aqua", borderBottom: "1px solid", cursor: "pointer" } : { borderBottom: '1px solid' }}>
                                {balance.toExact(2)}
                            </span>
                        </div>
                    }
                    <TokenSelect token={currency} onTokenSelect={onCurrencySelect} />
                </div>
            </div>
        </>
    )
}