import React, { useCallback, useEffect, useState } from "react";
import { CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react";
import { interval, unstakeBefore } from "../../config/pools";
import { KeyValue } from "../../Components/KeyValue";
import { toNumber } from "../../utils/toNumber";
import { calculateApr } from "../../utils/calculateAPR";
import moment from "moment";
import { formatCurrencyAmount } from "../../utils/formatCurrencyBalance";
import { useApproveCallback } from "../../hooks/useApproveCallback";
import { CurrencyAmount } from "@uniswap/sdk-core";
import { OCToken } from "../../config/tokens";
import { ethers } from "ethers";
import { ApprovalState } from "../../hooks/useApproval";
import { useStake } from "./hooks/useStake";
import { formatNumber, showToast } from "../../utils";
import { Toaster } from "react-hot-toast";
import { useStakeInfo } from "./hooks/useStakeInfo";

export const StakeModal = ({ apr, label, lockPeriod, address, ogAPR, onClose, show, ocBalance }) => {
    const [input, setInput] = useState(0);
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState("");

    const [ogTokens, setOGToken] = useState({
        perDay: 0,
        total: 0
    })

    const [ocTokens, setOCToken] = useState({
        perDay: 0,
        total: 0
    })

    const [lockedTill, setLockedTill] = useState(Date.now());

    const { staked } = useStakeInfo({address})

    const handleInput = useCallback((e) => {
        if (e.target.value) {
            setInput(e.target.value)
        } else {
            setInput("")
        }
    },[])

    const [approval, approveCallback] = useApproveCallback(CurrencyAmount.fromRawAmount(OCToken, ethers.utils.parseEther(input === "" ? "0": input.toString())), address)

    const { stake } = useStake({address})


    useEffect(() => {

        if ( input === "" || parseFloat(input) === 0 ) {
            setOGToken({
                perDay: 0,
                total: 0
            })
            setOCToken({
                perDay: 0,
                total: 0
            })
            setError("Enter amount to stake")
            return
        }

        if ( parseFloat(ocBalance.toFixed(18)) < parseFloat(input) ) {
            setOGToken({
                perDay: 0,
                total: 0
            })
            setOCToken({
                perDay: 0,
                total: 0
            })
            setError("Insufficient balance")
            return
        }

        const { perDayYield, totalOG } = calculateApr(parseFloat(input)+parseFloat(staked), ogAPR, lockPeriod)

        setOGToken({
            perDay: perDayYield,
            total: totalOG
        })

        const { perDayYield: perDay, totalOG: total } = calculateApr(parseFloat(input)+parseFloat(staked), apr, lockPeriod)
        setOCToken({
            perDay,
            total
        })

        setError("")
    }, [input, apr, ogAPR, lockPeriod, ocBalance, staked])

    useEffect(() => {
        const timer = setInterval(() => {
            setLockedTill(Date.now() + (lockPeriod * 1000))
        }, 1000)
        return () => {
            clearInterval(timer)
        };
    }, [input, setLockedTill, lockPeriod]);

    const stakecallback = useCallback(async () => {
        setLoading(true)
        const response = await stake(input)
        if (response) {
            onClose()
        } else {
            // showToast({
            //     message: "Something went wrong",
            //     type: "error"
            // })
        }
        setLoading(false)
    }, [stake, input, onClose])

    const setMaxBalance = useCallback(() => {
        // console.log(ocBalance)
        setInput(ocBalance.toExact())
    } , [ocBalance])

    const handleApprove = useCallback(async () => {
        setLoading(true)
        try {
            await approveCallback()
            showToast({
                message: "Approved successfully",
                type: "success"
            })
        } catch(err) {
            showToast({
                message: err.reason ? err.reason : ( err.data ? err.data.message : err.message ),
                type: "error"
            })
        }
        setLoading(false)
    }, [approveCallback])


    return (
        <CModal alignment="center" visible={show} onClose={onClose} className="previewModalBox stakeModalPopup">
            <CModalHeader>
                <CModalTitle>Stake for {label}</CModalTitle>
            </CModalHeader>
            <CModalBody>
                <div className="topheadTtl">
                    <p className="imgBx">
                        Earn OC rewards by staking OC tokens, with <b>{apr}% qAPR ( quantity APR )</b> and lock period
                        of <b>{lockPeriod / interval} days</b>.
                    </p>
                </div>
                <div className="main-box">
                    <div className="form-group flex flex-wrap justify-between align-items-center">
                        <label htmlFor="">Enter Amount (Min. 10 OC )</label>
                        <input type="number" value={input} onInput={handleInput} placeholder='0' />
                    </div>
                    <div className="token-balance"
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginRight: "5px" }}>
                        <div className="tokenDiv text-end my-2" style={{ marginLeft: "10px" }}>
                            Balance :
                            <span>
                                {ocBalance && ocBalance.toSignificant()} OC
                            </span>
                        </div>
                        <button class="btn" onClick={setMaxBalance} style={{ color: "white", margin: "5px" }}>Use Max</button>
                    </div>
                    <div class="stakeModalBtn">
                        {approval === ApprovalState.NOT_APPROVED && <button disabled={loading} onClick={handleApprove} class="btn">
                            {
                                loading ? "Loading..." : "Approve"
                            }
                        </button>}
                        {
                           approval === ApprovalState.APPROVED && <button class="btn" onClick={stakecallback} disabled={loading || error !== "" || approval === ApprovalState.NOT_APPROVED}>
                                {loading ? "Loading..." : (error !== "" ? error : "Stake")}
                            </button>
                        }
                    </div>
                </div>
                <hr />
                <div className="rowboxs">
                    <KeyValue label={"Your Staked"} value={`${toNumber(staked+parseFloat(input))} OC`} />
                    <KeyValue label={"Daily Rewards"} value={`${toNumber(ocTokens.perDay)} OC`} />
                    <KeyValue label={"Rewards At Maturity"} value={`${toNumber(ocTokens.total)} OC`} />
                    <hr />
                    <KeyValue label={"Daily Rewards"} value={`${toNumber(ogTokens.perDay)} OGT`} />
                    <KeyValue label={"Rewards At Maturity"} value={`${toNumber(ogTokens.total)} OGT`} />
                </div>

                <hr />
                <div>
                    <p>
                        If you unstake before the locking period ends ( <b>{moment(lockedTill).format("MMMM Do YYYY hh:mm:ss a")}</b> ), you'll be charged <b>{unstakeBefore}% ( {toNumber(parseFloat(input) * unstakeBefore / 100)} OC )</b> as an emergency fee, and your rewards will be lost.
                    </p>

                    <p>
                        <b>{toNumber(ogTokens.total)} OGT tokens</b> will be distributed as <b>a bonus token</b> per block, that you can claim anytime.
                    </p>
                </div>
            </CModalBody>
            <Toaster />
        </CModal>
    )
}