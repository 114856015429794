import { MaxUint256 } from '@ethersproject/constants'
import { useTokenContract } from './useContract'
import { useTokenAllowance } from './useTokenAllowance'
import { useCallback, useMemo } from 'react'
import { calculateGasMargin } from '../utils/calculateGasMargin'
import useActiveWeb3React from "./useWeb3";

export const ApprovalState = {
    UNKNOWN: 'UNKNOWN',
    NOT_APPROVED: 'NOT_APPROVED',
    PENDING: 'PENDING',
    APPROVED: 'APPROVED',
}

export function useApprovalStateForSpender(
    amountToApprove,
    spender
) {
    const { account } = useActiveWeb3React()
    const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

    const { tokenAllowance } = useTokenAllowance(token, account ?? undefined, spender)
    // const pendingApproval = useIsPendingApproval(token, spender)

    return useMemo(() => {
        if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
        if (amountToApprove.currency.isNative) return ApprovalState.APPROVED
        if (!tokenAllowance) return ApprovalState.UNKNOWN

        // amountToApprove will be defined if tokenAllowance is
        return tokenAllowance.lessThan(amountToApprove)
            ? ApprovalState.NOT_APPROVED
            : ApprovalState.APPROVED
    }, [amountToApprove, spender, tokenAllowance])
}

export function useApproval(
    amountToApprove,
    spender
) {
    const { chainId } = useActiveWeb3React()
    const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined
    // check the current approval status
    const approvalState = useApprovalStateForSpender(amountToApprove, spender)

    const tokenContract = useTokenContract(token?.address)

    const approve = useCallback(async () => {
        function logFailure(error) {
            console.warn(`${token?.symbol || 'Token'} approval failed:`, error)
            return
        }

        // Bail early if there is an issue.
        if (approvalState !== ApprovalState.NOT_APPROVED) {
            return logFailure('approve was called unnecessarily')
        } else if (!chainId) {
            return logFailure('no chainId')
        } else if (!token) {
            return logFailure('no token')
        } else if (!tokenContract) {
            return logFailure('tokenContract is null')
        } else if (!amountToApprove) {
            return logFailure('missing amount to approve')
        } else if (!spender) {
            return logFailure('no spender')
        }

        let useExact = false
        const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
            // general fallback for tokens which restrict approval amounts
            useExact = true
            return tokenContract.estimateGas.approve(spender, amountToApprove.quotient.toString())
        })

        return tokenContract
            .approve(spender, useExact ? amountToApprove.quotient.toString() : MaxUint256, {
                gasLimit: calculateGasMargin(estimatedGas),
            })
        // .then((response) => {
        //     return {
        //         response,
        //         tokenAddress: token.address,
        //         spenderAddress: spender,
        //     }
        // })
        // .catch((error: Error) => {
        //     console.debug("Failed to approve token", error, {
        //         tokenAddress: token.address,
        //         spenderAddress: spender,
        //         amountToApprove: amountToApprove.quotient.toString(),
        //     })
        //     showToast(error.reason ? error.reason : (error.data ? error.data.message: error.message), {type: "error"})
        //     // throw error
        // })
    }, [approvalState, token, tokenContract, amountToApprove, spender, chainId])

    return [approvalState, approve]
}
