import { useEffect, useMemo, useState } from "react"
import { useContract } from "../../../hooks/useContract"
import PoolABI from "../../../abi/poolABI.json"
import useActiveWeb3React from "../../../hooks/useWeb3"

export const useUnstake = ({address}) => {
    const { account } = useActiveWeb3React()
    const [ unstakeRequest, setUnstakeRequest ] = useState(false)
    const [ unstakePeriod, setUnstakePeriod ] = useState(0)
    const poolInstance = useContract( address, PoolABI )

    useEffect(() => {
        const inter = setInterval(async() => {
            if ( account ) {
                const userInfo = await poolInstance.stakers( account )
                const uCount =  userInfo.unstakeCount

                if ( parseFloat(uCount) > 0 ) {
                    const uInfo = await poolInstance.unstakeRequests( account, uCount-1 )
                    setUnstakeRequest( uInfo.isProcessed === 1 )
                    setUnstakePeriod( uInfo.timestamp )
                }
            }
        }, 6500)

        return () => clearInterval(inter)
    }, [setUnstakeRequest, setUnstakePeriod, account, poolInstance, unstakePeriod])

    useEffect(() => {
        (async() => {
            if ( account ) {
                const userInfo = await poolInstance.stakers( account )
                const uCount =  userInfo.unstakeCount
                if ( parseFloat(uCount) > 0 ) {
                    const uInfo = await poolInstance.unstakeRequests( account, uCount-1 )
                    setUnstakeRequest( uInfo.isProcessed === 1 )
                    console.debug("timestamp", uInfo)
                    setUnstakePeriod( uInfo.timestamp )
                }
            }
        })()
    }, [setUnstakeRequest, setUnstakePeriod, account, poolInstance])

    return useMemo(() => {
        return {
            unstakeRequest,
            unstakePeriod
        }
    }, [unstakeRequest, unstakePeriod])
}