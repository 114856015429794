import React from "react";

export const KeyValue = ({label, value}) => {
    return (
        <div className={"row"}>
            <div className={"col-6"}>
                {label}
            </div>
            <div className={"col-6 text-end"}>
                <b>{value}</b>
            </div>
        </div>
    )
}