import {useDispatch, useSelector} from "../../redux/store";
import {useCallback, useMemo} from "react";
import {fullRange, inputLeftRange, inputLiquidity, inputPrice, inputRightRange} from "./slice";
import {Bound, INPUTS} from "./config";
import {useSearchParams} from "react-router-dom";
import useActiveWeb3React from "../../hooks/useWeb3";
import {useCurrencyBalances} from "../../hooks/useTokenBalance";
import {PoolState, usePool} from "../../hooks/usePool";
import tryParseCurrencyAmount from "../../utils/tryParseCurrencyAmount";
import {CurrencyAmount, Price, Rounding} from "@uniswap/sdk-core";
import {
    encodeSqrtRatioX96,
    nearestUsableTick,
    Pool, Position,
    priceToClosestTick,
    TICK_SPACINGS,
    TickMath
} from "@uniswap/v3-sdk";
import JSBI from "jsbi";
import {tryParseTick} from "../../utils/tryParseTick";
import {getTickToPrice, tickToPrice} from "../../utils/getTickToPrice";
import {BIG_INT_ZERO} from "../../config";

export const useV3PoolState = () => {
    return useSelector(state => state.pool);
}

export const useV3PoolActionHandlers = (noLiquidity) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()

    const onInputA = useCallback((value) => {
        console.log("value", value)
        dispatch(inputLiquidity({ input: INPUTS.TOKEN_A, inputValue: value, noLiquidity: noLiquidity === true }))
    }, [dispatch, noLiquidity])

    const onInputB = useCallback((value) => {
        dispatch(inputLiquidity({ input: INPUTS.TOKEN_B, inputValue: value, noLiquidity: noLiquidity === true }))
    }, [dispatch, noLiquidity])

    const onLeftRangeInput = useCallback((value) => {
        dispatch(inputLeftRange({ inputValue: value }))
        const paramMinPrice = searchParams.get("minPrice")
        if(!paramMinPrice || ( paramMinPrice && paramMinPrice !== value)) {
            searchParams.set("minPrice", value)
            setSearchParams(searchParams)
        }
    }, [dispatch, searchParams, setSearchParams])

    const onRightRangeInput = useCallback((value) => {
        dispatch(inputRightRange({ inputValue: value }))
        const paramMaxPrice = searchParams.get("maxPrice")
        if(!paramMaxPrice || ( paramMaxPrice && paramMaxPrice !== value)) {
            searchParams.set("maxPrice", value)
            setSearchParams(searchParams)
        }
    }, [dispatch, searchParams, setSearchParams])

    const onStartPriceInput = useCallback(( value ) => {
        dispatch(inputPrice({inputValue: value }))
    }, [dispatch])

    return {
        onInputA,
        onInputB,
        onLeftRangeInput,
        onRightRangeInput,
        onStartPriceInput
    }
}

export const useV3PoolDerivedState = (
    _tokenA,
    _tokenB,
    feeAmount,
    _baseToken,
    existingPosition,
) => {
    const { account } = useActiveWeb3React()
    const { independentField, tokenAValue, leftRange, rightRange, startPrice } = useV3PoolState()

    const dependentField = independentField === INPUTS.TOKEN_A ? INPUTS.TOKEN_B : INPUTS.TOKEN_A

    const currencies = useMemo(() => {
        return {
            [INPUTS.TOKEN_A]: _tokenA,
            [INPUTS.TOKEN_B]: _tokenB
        }
    }, [_tokenA, _tokenB])

    const [tokenA, tokenB, baseToken] = useMemo(
        () => [_tokenA?.wrapped, _tokenB?.wrapped, _baseToken?.wrapped],
        [_tokenA, _tokenB, _baseToken]
    )

    const [ token0, token1 ] = useMemo(() => {
        return tokenA && tokenB ? (tokenA?.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA]) : [undefined, undefined]
    }, [tokenA, tokenB])

    const balances = useCurrencyBalances(account, [currencies[INPUTS.TOKEN_A], currencies[INPUTS.TOKEN_B]])

    const currencyBalances = {
        [INPUTS.TOKEN_A]: balances?.[0],
        [INPUTS.TOKEN_B]: balances?.[1]
    }

    const [poolState, pool] = usePool(currencies[INPUTS.TOKEN_A], currencies[INPUTS.TOKEN_B], feeAmount)
    const noLiquidity = poolState === PoolState.NOT_EXISTS

    const invertPrice = Boolean(baseToken && token0 && !baseToken.equals(token0))

    const price = useMemo(() => {
        // if no liquidity use typed value
        if (noLiquidity) {
            const parsedQuoteAmount = tryParseCurrencyAmount(startPrice, invertPrice ? token0 : token1)
            if (parsedQuoteAmount && token0 && token1) {
                const baseAmount = tryParseCurrencyAmount('1', invertPrice ? token1 : token0)
                const price =
                    baseAmount && parsedQuoteAmount
                        ? new Price(
                            baseAmount.currency,
                            parsedQuoteAmount.currency,
                            baseAmount.quotient,
                            parsedQuoteAmount.quotient
                        )
                        : undefined
                return (invertPrice ? price?.invert() : price) ?? undefined
            }
            return undefined
        } else {
            // get the amount of quote currency
            return pool && token0 ? pool.priceOf(token0) : undefined
        }
    }, [noLiquidity, startPrice, invertPrice, token1, token0, pool])

    const invalidPrice = useMemo(() => {
        const sqrtRatioX96 = price ? encodeSqrtRatioX96(price.numerator, price.denominator) : undefined
        return (
            price &&
            sqrtRatioX96 &&
            !(
                JSBI.greaterThanOrEqual(sqrtRatioX96, TickMath.MIN_SQRT_RATIO) &&
                JSBI.lessThan(sqrtRatioX96, TickMath.MAX_SQRT_RATIO)
            )
        )
    }, [price])

    const mockPool = useMemo(() => {
        if (tokenA && tokenB && feeAmount && price && !invalidPrice) {
            const currentTick = priceToClosestTick(price)
            const currentSqrt = TickMath.getSqrtRatioAtTick(currentTick)
            return new Pool(tokenA, tokenB, feeAmount, currentSqrt, JSBI.BigInt(0), currentTick, [])
        } else {
            return undefined
        }
    }, [feeAmount, invalidPrice, price, tokenA, tokenB])

    const poolForPosition = pool ?? mockPool

    const tickSpaceLimits = useMemo(
        () => ({
            [Bound.LOWER]: feeAmount ? nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[feeAmount]) : undefined,
            [Bound.UPPER]: feeAmount ? nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[feeAmount]) : undefined,
        }),
        [feeAmount]
    )

    const ticks = useMemo(() => {
        return {
            [Bound.LOWER]:
                typeof existingPosition?.tickLower === 'number'
                    ? existingPosition.tickLower
                    : (invertPrice && typeof rightRange === 'boolean') ||
                    (!invertPrice && typeof leftRange === 'boolean')
                        ? tickSpaceLimits[Bound.LOWER]
                        : invertPrice
                            ? tryParseTick(token1, token0, feeAmount, rightRange.toString())
                            : tryParseTick(token0, token1, feeAmount, leftRange.toString()),
            [Bound.UPPER]:
                typeof existingPosition?.tickUpper === 'number'
                    ? existingPosition.tickUpper
                    : (!invertPrice && typeof rightRange === 'boolean') ||
                    (invertPrice && typeof leftRange === 'boolean')
                        ? tickSpaceLimits[Bound.UPPER]
                        : invertPrice
                            ? tryParseTick(token1, token0, feeAmount, leftRange.toString())
                            : tryParseTick(token0, token1, feeAmount, rightRange.toString()),
        }
    }, [
        existingPosition,
        feeAmount,
        invertPrice,
        leftRange,
        rightRange,
        token0,
        token1,
        tickSpaceLimits,
    ])

    const { [Bound.LOWER]: tickLower, [Bound.UPPER]: tickUpper } = ticks || {}

    const ticksAtLimit = useMemo(
        () => ({
            [Bound.LOWER]: feeAmount && tickLower === tickSpaceLimits.LOWER,
            [Bound.UPPER]: feeAmount && tickUpper === tickSpaceLimits.UPPER,
        }),
        [tickSpaceLimits, tickLower, tickUpper, feeAmount]
    )

    const invalidRange = Boolean(typeof tickLower === 'number' && typeof tickUpper === 'number' && tickLower >= tickUpper)

    const pricesAtLimit = useMemo(() => {
        return {
            [Bound.LOWER]: getTickToPrice(token0, token1, tickSpaceLimits.LOWER),
            [Bound.UPPER]: getTickToPrice(token0, token1, tickSpaceLimits.UPPER),
        }
    }, [token0, token1, tickSpaceLimits.LOWER, tickSpaceLimits.UPPER])

    // always returns the price with 0 as base token
    const pricesAtTicks = useMemo(() => {
        return {
            [Bound.LOWER]: getTickToPrice(token0, token1, ticks[Bound.LOWER]),
            [Bound.UPPER]: getTickToPrice(token0, token1, ticks[Bound.UPPER]),
        }
    }, [token0, token1, ticks])
    const { [Bound.LOWER]: lowerPrice, [Bound.UPPER]: upperPrice } = pricesAtTicks

    const outOfRange = Boolean(
        !invalidRange && price && lowerPrice && upperPrice && (price.lessThan(lowerPrice) || price.greaterThan(upperPrice))
    )

    const independentAmount = tryParseCurrencyAmount(
        tokenAValue,
        currencies[independentField]
    )

    const dependentAmount = useMemo(() => {
        // we wrap the currencies just to get the price in terms of the other token
        const wrappedIndependentAmount = independentAmount?.wrapped
        const dependentCurrency = dependentField === INPUTS.TOKEN_B ? _tokenB : _tokenA
        if (
            independentAmount &&
            wrappedIndependentAmount &&
            typeof tickLower === 'number' &&
            typeof tickUpper === 'number' &&
            poolForPosition
        ) {
            // if price is out of range or invalid range - return 0 (single deposit will be independent)
            if (outOfRange || invalidRange) {
                return undefined
            }

            const position = wrappedIndependentAmount.currency.equals(poolForPosition.token0)
                ? Position.fromAmount0({
                    pool: poolForPosition,
                    tickLower,
                    tickUpper,
                    amount0: independentAmount.quotient,
                    useFullPrecision: true, // we want full precision for the theoretical position
                })
                : Position.fromAmount1({
                    pool: poolForPosition,
                    tickLower,
                    tickUpper,
                    amount1: independentAmount.quotient,
                })

            const dependentTokenAmount = wrappedIndependentAmount.currency.equals(poolForPosition.token0)
                ? position.amount1
                : position.amount0
            return dependentCurrency && CurrencyAmount.fromRawAmount(dependentCurrency, dependentTokenAmount.quotient)
        }

        return undefined
    }, [
        independentAmount,
        outOfRange,
        dependentField,
        _tokenB,
        _tokenA,
        tickLower,
        tickUpper,
        poolForPosition,
        invalidRange,
    ])

    const parsedAmounts = useMemo(() => {
        return {
            [INPUTS.TOKEN_A]: independentField === INPUTS.TOKEN_A ? independentAmount : dependentAmount,
            [INPUTS.TOKEN_B]: independentField === INPUTS.TOKEN_A ? dependentAmount : independentAmount,
        }
    }, [dependentAmount, independentAmount, independentField])

    // single deposit only if price is out of range
    const deposit0Disabled = Boolean(
        typeof tickUpper === 'number' && poolForPosition && poolForPosition.tickCurrent >= tickUpper
    )
    const deposit1Disabled = Boolean(
        typeof tickLower === 'number' && poolForPosition && poolForPosition.tickCurrent <= tickLower
    )

    // sorted for token order
    const depositADisabled =
        invalidRange ||
        Boolean(
            (deposit0Disabled && poolForPosition && tokenA && poolForPosition.token0.equals(tokenA)) ||
            (deposit1Disabled && poolForPosition && tokenA && poolForPosition.token1.equals(tokenA))
        )
    const depositBDisabled =
        invalidRange ||
        Boolean(
            (deposit0Disabled && poolForPosition && tokenB && poolForPosition.token0.equals(tokenB)) ||
            (deposit1Disabled && poolForPosition && tokenB && poolForPosition.token1.equals(tokenB))
        )

    // create position entity based on users selection
    const position = useMemo(() => {
        if (
            !poolForPosition ||
            !tokenA ||
            !tokenB ||
            typeof tickLower !== 'number' ||
            typeof tickUpper !== 'number' ||
            invalidRange
        ) {
            return undefined
        }

        // mark as 0 if disabled because out of range
        const amount0 = !deposit0Disabled
            ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? INPUTS.TOKEN_A : INPUTS.TOKEN_B]?.quotient
            : BIG_INT_ZERO
        const amount1 = !deposit1Disabled
            ? parsedAmounts?.[tokenA.equals(poolForPosition.token0) ? INPUTS.TOKEN_B : INPUTS.TOKEN_A]?.quotient
            : BIG_INT_ZERO

        if (amount0 !== undefined && amount1 !== undefined) {
            return Position.fromAmounts({
                pool: poolForPosition,
                tickLower,
                tickUpper,
                amount0,
                amount1,
                useFullPrecision: true, // we want full precision for the theoretical position
            })
        } else {
            return undefined
        }
    }, [
        parsedAmounts,
        poolForPosition,
        tokenA,
        tokenB,
        deposit0Disabled,
        deposit1Disabled,
        invalidRange,
        tickLower,
        tickUpper,
    ])

    let errorMessage
    if (!account) {
        errorMessage = <span>Connect Wallet</span>
    }

    if (poolState === PoolState.INVALID) {
        errorMessage = errorMessage ?? <span>Invalid pair</span>
    }

    if (invalidPrice) {
        errorMessage = errorMessage ?? <span>Invalid price input</span>
    }

    if (
        (!parsedAmounts[INPUTS.TOKEN_A] && !depositADisabled) ||
        (!parsedAmounts[INPUTS.TOKEN_B] && !depositBDisabled)
    ) {
        errorMessage = errorMessage ?? <span>Enter an amount</span>
    }

    const { [INPUTS.TOKEN_A]: currencyAAmount, [INPUTS.TOKEN_B]: currencyBAmount } = parsedAmounts

    if (currencyAAmount && currencyBalances?.[INPUTS.TOKEN_A]?.lessThan(currencyAAmount)) {
        errorMessage = <span>Insufficient {currencies[INPUTS.TOKEN_A]?.symbol} balance</span>
    }

    if (currencyBAmount && currencyBalances?.[INPUTS.TOKEN_B]?.lessThan(currencyBAmount)) {
        errorMessage = <span>Insufficient {currencies[INPUTS.TOKEN_B]?.symbol} balance</span>
    }

    const invalidPool = poolState === PoolState.INVALID

    return {
        dependentField,
        currencies,
        pool,
        poolState,
        currencyBalances,
        parsedAmounts,
        ticks,
        price,
        pricesAtTicks,
        pricesAtLimit,
        position,
        noLiquidity,
        errorMessage,
        invalidPool,
        invalidRange,
        outOfRange,
        depositADisabled,
        depositBDisabled,
        invertPrice,
        ticksAtLimit,
    }
}

export function useRangeHopCallbacks(
    baseCurrency,
    quoteCurrency,
    feeAmount,
    tickLower,
    tickUpper,
    pool?
) {
    const dispatch = useDispatch()

    const baseToken = useMemo(() => baseCurrency?.wrapped, [baseCurrency])
    const quoteToken = useMemo(() => quoteCurrency?.wrapped, [quoteCurrency])

    const getDecrementLower = useCallback(() => {
        if (baseToken && quoteToken && typeof tickLower === 'number' && feeAmount) {
            const newPrice = tickToPrice(baseToken, quoteToken, tickLower - TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        // use pool current tick as starting tick if we have pool but no tick input
        if (!(typeof tickLower === 'number') && baseToken && quoteToken && feeAmount && pool) {
            const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        return ''
    }, [baseToken, quoteToken, tickLower, feeAmount, pool])

    const getIncrementLower = useCallback(() => {
        if (baseToken && quoteToken && typeof tickLower === 'number' && feeAmount) {
            const newPrice = tickToPrice(baseToken, quoteToken, tickLower + TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        // use pool current tick as starting tick if we have pool but no tick input
        if (!(typeof tickLower === 'number') && baseToken && quoteToken && feeAmount && pool) {
            const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        return ''
    }, [baseToken, quoteToken, tickLower, feeAmount, pool])

    const getDecrementUpper = useCallback(() => {
        if (baseToken && quoteToken && typeof tickUpper === 'number' && feeAmount) {
            const newPrice = tickToPrice(baseToken, quoteToken, tickUpper - TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        // use pool current tick as starting tick if we have pool but no tick input
        if (!(typeof tickUpper === 'number') && baseToken && quoteToken && feeAmount && pool) {
            const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent - TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        return ''
    }, [baseToken, quoteToken, tickUpper, feeAmount, pool])

    const getIncrementUpper = useCallback(() => {
        if (baseToken && quoteToken && typeof tickUpper === 'number' && feeAmount) {
            const newPrice = tickToPrice(baseToken, quoteToken, tickUpper + TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        // use pool current tick as starting tick if we have pool but no tick input
        if (!(typeof tickUpper === 'number') && baseToken && quoteToken && feeAmount && pool) {
            const newPrice = tickToPrice(baseToken, quoteToken, pool.tickCurrent + TICK_SPACINGS[feeAmount])
            return newPrice.toSignificant(5, undefined, Rounding.ROUND_UP)
        }
        return ''
    }, [baseToken, quoteToken, tickUpper, feeAmount, pool])

    const getSetFullRange = useCallback(() => {
        dispatch(fullRange())
    }, [dispatch])

    return { getDecrementLower, getIncrementLower, getDecrementUpper, getIncrementUpper, getSetFullRange }
}

