import React, { useEffect, useMemo, useState } from "react";
import { defaultTokenImage } from "../../config";
import { useTokenImage } from "../../hooks/useTokenImage";
import useDebounce from "../../hooks/useDebounce";

export const Logo = ({ image }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    function handleImageLoad() {
        setImageLoaded(true);
    }

    return (
        <img style={{ backgroundColor: "#000", padding: '3px', borderRadius: '50%' }} alt={"token-logo"} src={image} onLoad={handleImageLoad} width="36" height="36" />
    )
}

export const InputLogo = ({ token }) => {

    const debounceToken = useDebounce(token, 500)
    const imageURL = useTokenImage(debounceToken)
    return (
        <Logo image={token ? imageURL : defaultTokenImage} />
    )
}