export const fetchURL = ( url ) => {
    return new Promise( async (resolve,) => {
        try {
            let response = await fetch(url, { method: "HEAD" })
            if ( response.ok ) {
                const img = new Image();
                img.src = url;
                img.onerror = () => {
                    resolve(false);
                };
                img.onload = () => {
                    resolve(true)
                }
            } else {
                resolve(false)
            }
        } catch (e) {
            console.error(e.message)
            resolve(false)
        }
    })
}