import { BigNumber } from '@ethersproject/bignumber'
import { useMemo } from 'react'

import useCurrentBlockTimestamp from './useCurrentBlockTimestamp'
import {L2_DEADLINE_FROM_NOW} from "../config/constants";
import {useSelector} from "../redux/store";
import useActiveWeb3React from "./useWeb3";

export default function useTransactionDeadline(): BigNumber | undefined {
    const { chainId } = useActiveWeb3React()
    const ttl = useSelector((state) => state.user.userDeadline)
    const blockTimestamp = useCurrentBlockTimestamp()
    return useMemo(() => {
        if (blockTimestamp && chainId) return blockTimestamp.add(L2_DEADLINE_FROM_NOW)
        if (blockTimestamp && ttl) return blockTimestamp.add(ttl)
        return undefined
    }, [blockTimestamp, chainId, ttl])
}
