import { BigNumber } from '@ethersproject/bignumber'
import { useSingleCallResult, useSingleContractMultipleData } from './multicall'
import { useMemo } from 'react'

import { usePositionManager } from './useContract'

function useV3PositionsFromTokenIds(tokenIds) {
    const positionManager = usePositionManager()
    const inputs = useMemo(() => (tokenIds ? tokenIds.map((tokenId) => [BigNumber.from(tokenId)]) : []), [tokenIds])
    const results = useSingleContractMultipleData(positionManager, 'positions', inputs)

    const loading = useMemo(() => results.some(({ loading }) => loading), [results])
    const error = useMemo(() => results.some(({ error }) => error), [results])

    const positions = useMemo(() => {
        if (!loading && !error && tokenIds) {
            return results.map((call, i) => {
                const tokenId = tokenIds[i]
                const result = call.result
                return {
                    tokenId,
                    fee: result.fee,
                    feeGrowthInside0LastX128: result.feeGrowthInside0LastX128,
                    feeGrowthInside1LastX128: result.feeGrowthInside1LastX128,
                    liquidity: result.liquidity,
                    nonce: result.nonce,
                    operator: result.operator,
                    tickLower: result.tickLower,
                    tickUpper: result.tickUpper,
                    token0: result.token0,
                    token1: result.token1,
                    tokensOwed0: result.tokensOwed0,
                    tokensOwed1: result.tokensOwed1,
                }
            })
        }
        return undefined
    }, [loading, error, results, tokenIds])

    return {
        loading,
        positions: positions?.map((position, i) => ({ ...position, tokenId: inputs[i][0] })),
    }
}

export function useV3PositionFromTokenId(tokenId) {
    const position = useV3PositionsFromTokenIds(tokenId ? [tokenId] : undefined)
    return {
        loading: position.loading,
        position: position.positions?.[0],
    }
}

export function useV3Positions(account) {
    const positionManager = usePositionManager()

    const { loading: balanceLoading, result: balanceResult } = useSingleCallResult(positionManager, 'balanceOf', [
        account ?? undefined,
    ])

    // we don't expect any account balance to ever exceed the bounds of max safe int
    const accountBalance: number | undefined = balanceResult?.[0]?.toNumber()

    const tokenIdsArgs = useMemo(() => {
        if (accountBalance && account) {
            const tokenRequests = []
            for (let i = 0; i < accountBalance; i++) {
                tokenRequests.push([account, i])
            }
            return tokenRequests
        }
        return []
    }, [account, accountBalance])

    const tokenIdResults = useSingleContractMultipleData(positionManager, 'tokenOfOwnerByIndex', tokenIdsArgs)
    const someTokenIdsLoading = useMemo(() => tokenIdResults.some(({ loading }) => loading), [tokenIdResults])

    const tokenIds = useMemo(() => {
        if (account) {
            return tokenIdResults
                .map(({ result }) => result)
                .filter((result): result => !!result)
        .map((result) => BigNumber.from(result[0]))
        }
        return []
    }, [account, tokenIdResults])

    const { positions, loading: positionsLoading } = useV3PositionsFromTokenIds(tokenIds)

    return {
        loading: someTokenIdsLoading || balanceLoading || positionsLoading,
        positions,
    }
}
