// import { formatUnits } from "ethers";
import { formatUnits } from "@ethersproject/units";
import { getAddress } from "ethers/lib/utils";
import { toast } from "react-hot-toast";

export function shortAddress(address) {
    return `${address.substr(0, 4)}...${address.substr(-4)}`;
}

export function formatNumber(val, decimals = 18, points = 4) {
    return parseFloat(parseFloat(formatUnits(val, decimals)).toFixed(points)).toLocaleString()
}

export function formatNewNumber(val, decimals = 18) {
    return parseFloat(formatUnits(val, decimals)).toString()
}

export function toLocaleString(value) {
    return parseFloat(value !== NaN ? value : "0").toLocaleString()
}

export const showToast = async ({ message = "", type = 'success', position = 'bottom-right', promise = null, loading = 'Awaiting transaction response', success = "Transaction Confirmed" }) => {
    switch (type) {
        case 'success':
            toast.success(message, {
                position,
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                }
            })
            break;
        case 'error':
            toast.error(message, {
                position,
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                }
            })
            break;
        case 'promise':
            return toast.promise(
                promise ?? Promise.resolve(),
                {
                    loading,
                    success,
                    error: (error) => { return error.reason ? error.reason : (error.data ? error.data.message : error.message) }
                },
                {
                    position
                }
            )
        default:
            toast(message, {
                position,
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                }
            })
            break;
    }
}

export function isAddress(value) {
    try {
        return getAddress(value.toLowerCase())
    } catch {
        return false
    }
}

export const localeFixed = (value, fixed = 2) => {
    return parseFloat(value) !== 0 ? parseFloat(parseFloat(value).toFixed(fixed)).toLocaleString() : 0
}