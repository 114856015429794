import React, { useCallback, useState } from "react";
import SelectToken from "../SelectToken/SelectToken";
import { InputLogo } from "../Logo";

export const TokenSelect = ({
    token, onTokenSelect = () => { }
}) => {
    const [visibleSlot, setVisibleSlot] = useState(false)

    const _onTokenSelect = useCallback((token) => {
        setVisibleSlot(false)
        onTokenSelect(token)
    }, [onTokenSelect, setVisibleSlot])

    return (
        <>
            <div className="token-currency" onClick={() => setVisibleSlot(true)}>
                <div className="bg-logo">
                    <InputLogo token={token} />
                </div>
                <span>{
                    token?.symbol ?? "Select token"
                }</span>
            </div>
            <SelectToken onTokenSelect={_onTokenSelect} visibleSlot={visibleSlot} setVisibleSlot={setVisibleSlot} selectedToken={token} />
        </>
    )
}