import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import usdtImg from '../../Assets/images/usdt.png'
import twtImg from '../../Assets/images/TWT.png'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import LiquidityModal from '../../Components/LiquidityModal/LiquidityModal';
import RemoveLiquidityModal from '../../Components/RemoveLiquidityModal/RemoveLiquidityModal';
const NftLiquidity = (props) => {
    // Increase Liquidity Modal
    const [visiblePreview, setVisiblePreview] = useState(false)
    // Remove Liquidity Modal
    const [visibleRemoveLiquidity, setRemoveLiquidity] = useState(false)
    return (
        <>
            <section className='nfts' id='nfts'>
                <div className="nfts-container ox_container">
                    <div className='connectBtnMain poolsBoxHead'>
                        <NavLink className={"backIcon"} to={"/pool"}>
                            <h2><span>&#60;</span> Back to Pools</h2>
                        </NavLink>
                    </div>
                    <div className='poolsBox'>
                        <div className='nftLiquidity'>
                            <div className="topheadTtl">
                                <h2 className="imgBx">
                                    <img src={twtImg} alt="TWT" />
                                    <img src={usdtImg} alt="USDT" />
                                    <strong>OC/MATIC</strong>
                                    <b>0.05%</b>
                                    <span>In Rage</span>
                                </h2>
                                <div className='btnGrp'>
                                    <button className="btnLiq" onClick={() => setVisiblePreview(!visiblePreview)}>Increase Liquidity</button>
                                    <button className='btnLiq' onClick={() => setRemoveLiquidity(!visibleRemoveLiquidity)}>Remove Liquidity</button>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12 col-lg-6'>
                                    <div className='innerbox'>
                                        <div className="innerboxImg"></div>
                                    </div>
                                </div>
                                <div className='col-12 col-lg-6'>
                                    <div className='innerbox'>
                                        <div className='liquidityBox'>
                                            <h4>Liquidity</h4>
                                            <h2>$-</h2>
                                        </div>
                                        <div className='liquidityTable'>
                                            <div className='rowBoxTbl'>
                                                <h2 className="imgBx">
                                                    <img src={twtImg} alt="TWT" />
                                                    <strong>OC/MATIC</strong>
                                                </h2>
                                                <div className='rightBx'>
                                                    <strong>0.02141</strong>
                                                    <span>29%</span>
                                                </div>
                                            </div>
                                            <div className='rowBoxTbl'>
                                                <h2 className="imgBx">
                                                    <img src={twtImg} alt="TWT" />
                                                    <strong>OC/MATIC</strong>
                                                </h2>
                                                <div className='rightBx'>
                                                    <strong>0.02141</strong>
                                                    <span>29%</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='liquidityTable'>
                                            <div className='rowBoxTbl'>
                                                <h2 className="imgBx">
                                                    <img src={twtImg} alt="TWT" />
                                                    <strong>OC/MATIC</strong>
                                                </h2>
                                                <div className='rightBx'>
                                                    <strong>0.02141</strong>
                                                    <span>29%</span>
                                                </div>
                                            </div>
                                            <div className='rowBoxTbl'>
                                                <h2 className="imgBx">
                                                    <img src={twtImg} alt="TWT" />
                                                    <strong>OC/MATIC</strong>
                                                </h2>
                                                <div className='rightBx'>
                                                    <strong>0.02141</strong>
                                                    <span>29%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="priceRangeBox">
                                        <Tabs>
                                            <TabList>
                                                <h2>Price Range <span>In Range</span></h2>
                                                <div className="tabmenu">
                                                    <Tab>TWT</Tab>
                                                    <Tab>USDT</Tab>
                                                </div>
                                            </TabList>

                                            <TabPanel>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <div className="infoBox">
                                                            <strong>Min Price</strong>
                                                            <h3>0.100</h3>
                                                            <strong>USDT per TWT</strong>
                                                            <p>Your position will be 100% composed of OC at this price</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <div className="infoBox">
                                                            <strong>Max Price</strong>
                                                            <h3>2.00</h3>
                                                            <strong>USDT per TWT</strong>
                                                            <p>Your position will be 100% composed of TWT at this price</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 mb-3">
                                                        <div className="infoBox">
                                                            <strong>Current price</strong>
                                                            <h3>1</h3>
                                                            <strong>USDT per TWT</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                            <TabPanel>
                                                <div className="row">
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <div className="infoBox">
                                                            <strong>Max Price</strong>
                                                            <h3>2.00</h3>
                                                            <strong>USDT per TWT</strong>
                                                            <p>Your position will be 100% composed of TWT at this price</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6 mb-3">
                                                        <div className="infoBox">
                                                            <strong>Min Price</strong>
                                                            <h3>0.100</h3>
                                                            <strong>USDT per TWT</strong>
                                                            <p>Your position will be 100% composed of USDT at this price</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-12 mb-3">
                                                        <div className="infoBox">
                                                            <strong>Current price</strong>
                                                            <h3>2</h3>
                                                            <strong>TWT per USDT</strong>
                                                        </div>
                                                    </div>
                                                </div>
                                            </TabPanel>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LiquidityModal visiblePreview={visiblePreview} setVisiblePreview={setVisiblePreview} />
            <RemoveLiquidityModal visibleRemoveLiquidity={visibleRemoveLiquidity} setRemoveLiquidity={setRemoveLiquidity} />
        </>
    )
}

export default NftLiquidity