
export function swapErrorToUserReadableMessage(error) {
    let reason
    while (Boolean(error)) {
        reason = error.reason ?? error.message ?? reason
        error = error.error ?? error.data?.originalError
    }

    if (reason?.indexOf('execution reverted: ') === 0) reason = reason.substr('execution reverted: '.length)

    switch (reason) {
        case 'TransferHelper: TRANSFER_FROM_FAILED':
            return <>The input token cannot be transferred. There may be an issue with the input token.</>
        case 'Too little received':
        case 'Too much requested':
        case 'STF':
            return (
                <>
                    This transaction will not succeed due to price movement. Try increasing your slippage tolerance. Note: fee on
                    transfer and rebase tokens are incompatible with OxChange V3.
                </>
            )
        case 'TF':
            return (
                <>
                    The output token cannot be transferred. There may be an issue with the output token. Note: fee on transfer and
                    rebase tokens are incompatible with OxChange V3.
                </>
            )
        default:
            if (reason?.indexOf('undefined is not an object') !== -1) {
                console.error(error, reason)
                return (
                    <>
                        An error occurred when trying to execute this swap. You may need to increase your slippage tolerance. If
                        that does not work, there may be an incompatibility with the token you are trading. Note: fee on transfer
                        and rebase tokens are incompatible with OxChange V3.
                    </>
                )
            }
            return (
                <>
                    Unknown error{reason ? `: "${reason}"` : ''}. Try increasing your slippage tolerance. Note: fee on transfer
                    and rebase tokens are incompatible with OxChange V3.
                </>
            )
    }
}
