import { useToaster } from "react-hot-toast"
import useActiveWeb3React from "../useWeb3"
import { useTokenAllowance } from "../useTokenAllowance"
import { CONTRACTS } from "../../config/contracts"
import { useApproveCallback } from "../useApproveCallback"
import { useCallback, useMemo, useState } from "react"
import { showError } from "../../utils/pageHelpers"
import { useApproval, useApprovalStateForSpender } from "../useApproval"

export const useApproveForUniversal = (currencyAmount, allowedSlippage) => {
    const { chainId } = useActiveWeb3React()

    const amountToApprove = useMemo(
        () => currencyAmount?.currency?.isToken ? currencyAmount : undefined,
        [currencyAmount, allowedSlippage]
    )

    // console.debug("amountToApprove", currencyAmount, amountToApprove)

    const [approvalState, approve] = useApproval(amountToApprove, CONTRACTS.UNIVERSAL_ROUTER[chainId])
    return {
        approvalState,
        approve
    }
}