import { SwapRouter } from '@uniswap/router-sdk'
import { SwapRouter as V3SwapRouter, Trade as V3Trade } from '@uniswap/v3-sdk'
import useActiveWeb3React from '../hooks/useWeb3'
import { useMemo } from 'react'
import {CONTRACTS} from "../config/contracts";

export function useSwapCallArguments(
    trade,
    allowedSlippage,
    recipientAddressOrName,
    signatureData,
    deadline,
    feeOptions,
) {
    const { account, chainId, library } = useActiveWeb3React()
    const recipient = recipientAddressOrName === null ? account : recipientAddressOrName
    // const routerContract = useV2RouterContract()
    // const argentWalletContract = useArgentWalletContract()

    return useMemo(() => {
        if (!trade || !recipient || !library || !account || !chainId || !deadline) return []

        // if (trade instanceof V2Trade) {
        //     if (!routerContract) return []
        //     const swapMethods = []
        //
        //     swapMethods.push(
        //         V2SwapRouter.swapCallParameters(trade, {
        //             feeOnTransfer: false,
        //             allowedSlippage,
        //             recipient,
        //             deadline: deadline.toNumber(),
        //         })
        //     )
        //
        //     if (trade.tradeType === TradeType.EXACT_INPUT) {
        //         swapMethods.push(
        //             V2SwapRouter.swapCallParameters(trade, {
        //                 feeOnTransfer: true,
        //                 allowedSlippage,
        //                 recipient,
        //                 deadline: deadline.toNumber(),
        //             })
        //         )
        //     }
        //     return swapMethods.map(({ methodName, args, value }) => {
        //         if (argentWalletContract && trade.inputAmount.currency.isToken) {
        //             return {
        //                 address: argentWalletContract.address,
        //                 calldata: argentWalletContract.interface.encodeFunctionData('wc_multiCall', [
        //                     [
        //                         approveAmountCalldata(trade.maximumAmountIn(allowedSlippage), routerContract.address),
        //                         {
        //                             to: routerContract.address,
        //                             value,
        //                             data: routerContract.interface.encodeFunctionData(methodName, args),
        //                         },
        //                     ],
        //                 ]),
        //                 value: '0x0',
        //             }
        //         } else {
        //             return {
        //                 address: routerContract.address,
        //                 calldata: routerContract.interface.encodeFunctionData(methodName, args),
        //                 value,
        //             }
        //         }
        //     })
        // } else {
            // swap options shared by v3 and v2+v3 swap routers
            const sharedSwapOptions = {
                fee: feeOptions,
                recipient,
                slippageTolerance: allowedSlippage,
                ...(signatureData
                    ? {
                        inputTokenPermit:
                            'allowed' in signatureData
                                ? {
                                    expiry: signatureData.deadline,
                                    nonce: signatureData.nonce,
                                    s: signatureData.s,
                                    r: signatureData.r,
                                    v: signatureData.v
                                }
                                : {
                                    deadline: signatureData.deadline,
                                    amount: signatureData.amount,
                                    s: signatureData.s,
                                    r: signatureData.r,
                                    v: signatureData.v
                                },
                    }
                    : {}),
            }

            const swapRouterAddress = chainId
                ? trade instanceof V3Trade
                    ? CONTRACTS.SWAP_ROUTER_02[chainId]
                    : CONTRACTS.SWAP_ROUTER[chainId]
                : undefined
            if (!swapRouterAddress) return []

            const { value, calldata } =
                !(trade instanceof V3Trade)
                    ? V3SwapRouter.swapCallParameters(trade, {
                        ...sharedSwapOptions,
                        deadline: deadline.toString(),
                    })
                    : SwapRouter.swapCallParameters(trade, {
                        ...sharedSwapOptions,
                        deadlineOrPreviousBlockhash: deadline.toString(),
                    })

            return [
                {
                    address: swapRouterAddress,
                    calldata,
                    value,
                },
            ]
        // }
    }, [
        account,
        allowedSlippage,
        chainId,
        deadline,
        feeOptions,
        library,
        recipient,
        signatureData,
        trade,
    ])
}
