import PropTypes from "prop-types";
import React, { useState } from 'react';
import { CModal, CModalHeader, CModalBody, CModalTitle, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CNav, CNavItem, CNavLink, CTabContent, CTabPane } from '@coreui/react';
import btcIcon from '../../Assets/images/btc.png'
import {defaultTokens, PINNED_TOKENS, Token} from "../../config/tokens";
import {TokenHeaderItem} from "../TokenItem";
import {TokenListItem} from "../TokenItem/TokenListItem";

const SelectToken = ({ visibleSlot, setVisibleSlot, selectedToken, onTokenSelect }) => {
    const [valueToken, setValueToken] = useState(true);
    const [activeTab, setActiveTab] = useState(1);
    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };
    const handleCheckToken = () => {
        setValueToken(!valueToken);
    };
    const [switchOn, setSwitchOn] = useState(false);

    const handleSwitchChange = () => {
        setSwitchOn(!switchOn);
    }

    return (
        <>
            {/* <CModal alignment="center" visible={visibleWlt} onClose={() => props.setVisibleWlt(false)} className="connectWalletToggle"></CModal> */}
            <CModal alignment="center" visible={visibleSlot} onClose={() => setVisibleSlot(false)} className="selectTokenMdl">
                {valueToken ?
                    <>
                        <CModalHeader>
                            <CModalTitle>Select a token</CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <div className='inputBox' style={{display: 'none'}}>
                                <input type="text" autoFocus placeholder={"Enter Address or TokenItem Name"} />
                            </div>
                            <ul className='listViewSel'>
                                {
                                    PINNED_TOKENS.map(( token , idx) => {
                                        return <TokenHeaderItem key={idx} token={token} onTokenSelect={onTokenSelect} selectedToken={selectedToken} />
                                    })
                                }
                            </ul>
                            <ul className='gridViewSel'>
                                {
                                    defaultTokens.map((token, idx) => <TokenListItem onTokenSelect={onTokenSelect} key={idx} token={token} selectedToken={selectedToken} />)
                                }
                            </ul>
                        </CModalBody>
                        {/*<CModalFooter>*/}
                        {/*    <CButton onClick={handleCheckToken}>*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>*/}
                        {/*        <sapn>Manage TokenItem Lists</sapn>*/}
                        {/*    </CButton>*/}
                        {/*</CModalFooter>*/}
                    </>
                    :
                    <>
                        <CModalHeader>
                            <CModalTitle>
                                <span className='backIcon' onClick={handleCheckToken}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="19" y1="12" x2="5" y2="12"></line><polyline points="12 19 5 12 12 5"></polyline></svg></span>
                                <span>Manage</span>
                            </CModalTitle>
                        </CModalHeader>
                        <CModalBody>
                            <CNav>
                                <CNavItem>
                                    <CNavLink active={activeTab === 1} onClick={() => toggle(1)}>
                                        Lists
                                    </CNavLink>
                                </CNavItem>
                                <CNavItem>
                                    <CNavLink active={activeTab === 2} onClick={() => toggle(2)}>
                                        Tokens
                                    </CNavLink>
                                </CNavItem>
                            </CNav>
                            <div className='inputBox'>
                                <input type="text" autoFocus />
                            </div>
                            <CTabContent>
                                <CTabPane visible={activeTab === 1}>
                                    <li>
                                        <div className='inrBx'>
                                            <img src={btcIcon} alt="btcIcon" width="24" height="24" />
                                            <p>BTC Tokens List<span>15 tokens
                                                <CDropdown>
                                                    <CDropdownToggle color="secondary"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg></CDropdownToggle>
                                                    <CDropdownMenu>
                                                        <CDropdownItem disabled>v0.1.0</CDropdownItem>
                                                        <CDropdownItem href="#">View List</CDropdownItem>
                                                        <CDropdownItem href="#">Remove List</CDropdownItem>
                                                    </CDropdownMenu>
                                                </CDropdown></span></p>
                                        </div>
                                        <strong>
                                            <label className='switchOnOff'>
                                                <input
                                                    type="checkbox"
                                                    checked={switchOn}
                                                    onChange={handleSwitchChange}
                                                />
                                                <span></span>
                                            </label>
                                        </strong>
                                    </li>
                                </CTabPane>
                                <CTabPane visible={activeTab === 2}><li><b>0 Custom Tokens</b></li></CTabPane>
                            </CTabContent>
                        </CModalBody>
                    </>
                }
            </CModal>
        </>
    )
}

SelectToken.propTypes = {
    visibleSlot: PropTypes.bool,
    setVisibleSlot: PropTypes.func,
    selectedToken: PropTypes.instanceOf(Token),
    onTokenSelect: PropTypes.func
}
export default SelectToken