import PropTypes from 'prop-types'
import React, {useCallback} from "react";
import {useTokenImage} from "../../hooks/useTokenImage";
import {Token} from "../../config/tokens";
import {useCurrency} from "../../hooks/Tokens";

export const TokenHeaderItem = ({ token, selectedToken, onTokenSelect }) => {
    const wrappedToken = useCurrency(token?.isNative ? 'MATIC' : token.address)
    const tokenImage = useTokenImage(token)

    const _onTokenSelect = useCallback(() => {
        onTokenSelect(token)
    }, [token, onTokenSelect])

    return (
        <li onClick={_onTokenSelect} className={ wrappedToken?.symbol === selectedToken?.symbol && "active" }>
            <img src={tokenImage} alt={token?.address} width="24" height="24" />
            {wrappedToken?.symbol}
        </li>
    )
}

TokenHeaderItem.propTypes = {
    token: PropTypes.instanceOf(Token),
    selectedToken: PropTypes.instanceOf(Token),
    onTokenSelect: PropTypes.func
}
