import { ethers } from "ethers"
import UniversalSwapABI from "../../abi/universalSwap.json"
import { CONTRACTS } from "../../config/contracts"
import { OCToken } from "../../config/tokens"
import { useContract } from "../useContract"
import useActiveWeb3React from "../useWeb3"
import { WMATIC_POLYGON } from "@uniswap/smart-order-router"
import { useCallback, useEffect, useMemo, useState } from "react"
import { showError, showFulfill, showPromise } from "../../utils/pageHelpers"

export const useSwapData = (tokenIn, tokenOut, typedValue) => {
    const { chainId } = useActiveWeb3React()
    const swapContract = useContract(CONTRACTS.UNIVERSAL_ROUTER[chainId], UniversalSwapABI)

    const [swapOutput, setSwapOutput] = useState(0)
    const [decimals, setDecimals] = useState(18)
    const [isSwapping, setIsSwapping] = useState(false)

    const [error, setError] = useState(null)
    const wmaticAddress = WMATIC_POLYGON.address

    const isNativeIn = tokenIn?.isNative
    const isNativeOut = tokenOut?.isNative

    const fetchSwapOutput = useCallback(async () => {
        if (!swapContract || !tokenIn || !tokenOut || !typedValue) return
        try {
            if (isNativeIn && tokenOut.address.toLowerCase() === OCToken.address.toLowerCase()) {
                // const swapOutput = await swapContract.callStatic.buyOcWithAny(wmaticAddress, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, 18), 500, true, {
                //     value: ethers.utils.parseUnits(typedValue, 18)
                // })
                const swapOutput = await swapContract.callStatic.buyAnyWithAny(ethers.utils.parseUnits(typedValue, 18), [
                    wmaticAddress,
                    OCToken.address
                ], [
                    "10000"
                ], true, false, {
                    value: ethers.utils.parseUnits(typedValue, 18)
                })
                setDecimals(18)
                setSwapOutput(swapOutput)
            }
            if (!isNativeIn && !isNativeOut && tokenOut.address.toLowerCase() === OCToken.address.toLowerCase()) {
                // const swapOutput = await swapContract.callStatic.buyOcWithAny(tokenIn.address, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, tokenIn.decimals), 500, false)
                const swapOutput = await swapContract.callStatic.buyAnyWithAny(ethers.utils.parseUnits(typedValue, tokenIn.decimals), [
                    tokenIn.address,
                    wmaticAddress,
                    OCToken.address
                ], [
                    "500",
                    "10000"
                ], false, false)

                setDecimals(18)
                setSwapOutput(swapOutput)
            }

            if (isNativeOut && tokenIn.address.toLowerCase() === OCToken.address.toLowerCase()) {
                // console.debug("here")
                // const swapOutput = await swapContract.callStatic.sellOcForAny(wmaticAddress, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, 18), 500, false)
                const swapOutput = await swapContract.callStatic.buyAnyWithAny(ethers.utils.parseUnits(typedValue, 18), [
                    OCToken.address,
                    wmaticAddress
                ], [
                    "10000"
                ], false, true)
                setDecimals(18)
                setSwapOutput(swapOutput)
            }
            if (!isNativeIn && !isNativeOut && tokenIn.address?.toLowerCase() === OCToken.address.toLowerCase()) {
                // const swapOutput = await swapContract.callStatic.sellOcForAny(tokenOut.address, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, 18), 500, false)

                const swapOutput = await swapContract.callStatic.buyAnyWithAny(ethers.utils.parseUnits(typedValue, 18), [
                    OCToken.address,
                    wmaticAddress,
                    tokenOut.address
                ], [
                    "10000",
                    "500"
                ], false, false)
                setDecimals(tokenOut.decimals)
                // update
                
                
                setSwapOutput(swapOutput)
            }
            setError(null)
        } catch (err) {
            setError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
            console.debug("callerror", err.reason ? err.reason : (err.data ? err.data.message : err.message))
        }
    }, [swapContract, tokenIn, isNativeIn, isNativeOut, setError, tokenOut, typedValue, wmaticAddress])

    const swapCallback = useCallback(async () => {
        if (!swapContract || !tokenIn || !tokenOut || !typedValue) return
        try {
            if (!isNativeIn && tokenIn.address?.toLowerCase() === OCToken.address.toLowerCase()) {
                if (isNativeOut) {
                    setIsSwapping(true)
                    // let txn = await swapContract.sellOcForAny(wmaticAddress, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, 18), 500, true)
                    let txn = await swapContract.buyAnyWithAny(ethers.utils.parseUnits(typedValue, 18), [
                        OCToken.address,
                        wmaticAddress
                    ], [
                        "10000"
                    ], false, true)
                    showFulfill()
                    await showPromise(txn.wait())

                    showFulfill(`Sold ${typedValue} ${tokenIn.symbol} for ${(swapOutput / (10 ** decimals)).toFixed(4)} ${tokenOut.symbol}`, "Swap Complete")
                    setIsSwapping(false)
                } else {
                    setIsSwapping(true)
                    // let txn = await swapContract.sellOcForAny(tokenOut.address, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, 18), 500, false)
                    let txn = await swapContract.buyAnyWithAny(ethers.utils.parseUnits(typedValue, 18),
                    [
                        OCToken.address,
                        wmaticAddress,
                        tokenOut.address
                    ],
                    [
                        "10000",
                        "500"
                    ], false, false)
                    showFulfill()
                    await showPromise(txn.wait())

                    showFulfill(`Sold ${typedValue} ${tokenIn.symbol} for ${(swapOutput / (10 ** decimals)).toFixed(4)} ${tokenOut.symbol}`, "Swap Complete")
                    setIsSwapping(false)
                }
            } else {
                if (isNativeIn) {
                    setIsSwapping(true)
                    // let txn = await swapContract.buyOcWithAny(wmaticAddress, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, 18), 500, true, {
                    //     value: ethers.utils.parseUnits(typedValue, 18)
                    // })
                    let txn = await swapContract.buyAnyWithAny(ethers.utils.parseUnits(typedValue, 18), [
                        wmaticAddress,
                        OCToken.address
                    ], [
                        "10000"
                    ], true, false, {
                        value: ethers.utils.parseUnits(typedValue, 18)
                    })
                    showFulfill()
                    await showPromise(txn.wait())

                    showFulfill(`Bought ${(swapOutput / (10 ** decimals)).toFixed(4)} ${tokenOut.symbol} with ${typedValue} ${tokenIn.symbol}`, "Swap Complete")
                    setIsSwapping(false)
                } else {
                    setIsSwapping(true)
                    // let txn = await swapContract.buyOcWithAny(tokenIn.address, wmaticAddress, ethers.constants.AddressZero, ethers.utils.parseUnits(typedValue, tokenIn.decimals), 500, false)
                    let txn = await swapContract.buyAnyWithAny(ethers.utils.parseUnits(typedValue, tokenIn.decimals), [
                        tokenIn.address,
                        wmaticAddress,
                        OCToken.address
                    ],[
                        "500",
                        "10000"
                    ], false, false)
                    showFulfill()
                    await showPromise(txn.wait())

                    showFulfill(`Bought ${(swapOutput / 1e18).toFixed(2)} ${tokenOut.symbol} with ${parseFloat(typedValue).toFixed(4)} ${tokenIn.symbol}`, "Swap Complete")
                    setIsSwapping(false)
                }
            }
        } catch (err) {
            // setError(err.reason ? err.reason : (err.data ? err.data.message : err.message))
            console.debug("callerror", err.reason ? err.reason : (err.data ? err.data.message : err.message))
            showError(`Try again, swap error - ` + (err.reason ? err.reason : (err.data ? err.data.message : err.message)))
            setIsSwapping(false)
        }
    }, [swapContract, tokenIn, isNativeIn, isNativeOut, setError, tokenOut, typedValue, wmaticAddress, swapOutput, setIsSwapping, decimals])

    useEffect(() => {
        fetchSwapOutput()
    }, [fetchSwapOutput])

    return useMemo(() => {
        return { swapOutput, decimals, error, swapCallback, isSwapping }
    }, [swapOutput, error, decimals, swapCallback, isSwapping])
}