import React from 'react';
import { AddLiquidity } from "../AddLiquidity";
import usdtImg from '../../Assets/images/usdt.png'
import twtImg from '../../Assets/images/TWT.png'
import { NavLink } from 'react-router-dom'

const PoolCom = () => {

    return (
        <>
            <section className='nfts' id='nfts'>
                <div className="nfts-container ox_container">
                    <div className='connectBtnMain poolsBoxHead'>
                        <h2>Pools Overview</h2>
                        <AddLiquidity />
                    </div>
                    <div className='poolsBox'>
                        {/* Box 1 */}
                        <div className="poolsBoxInner">
                            <div className='poolBoxDefault'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round"><polyline points="22 12 16 12 14 15 10 15 8 12 2 12"></polyline><path d="M5.45 5.11L2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z"></path></svg>
                                <p>Your active V3 liquidity positions will appear here.</p>
                                {/* {
                                    !account && <ConnectWalletModal />
                                } */}
                            </div>
                            {/* ============================================ */}
                            {/* Box 2 */}
                            {/* <div className='yourPositionBox'>
                                <div className='headTitle'>
                                    <h2>Your position (1)</h2>
                                    <a href="#!">Hide closed positions</a>
                                </div>
                                <div className='rowBoxItems'>
                                    <NavLink to="/nftliquidity">
                                        <div className="topheadTtl">
                                            <h2 className="imgBx">
                                                <img src={twtImg} alt="TWT" />
                                                <img src={usdtImg} alt="USDT" />
                                                <strong>OC/MATIC</strong>
                                            </h2>
                                            <span>In Rage</span>
                                        </div>
                                        <p><span>Min:</span>0.500 TWT per USDT
                                            <svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>arrow-left-right</title>
                                                <path d="M30.693 16.287l0.001-0.013c0.034-0.081 0.054-0.175 0.055-0.274v-0c-0.005-0.037-0.012-0.070-0.021-0.101l0.001 0.005c-0.005-0.036-0.012-0.069-0.020-0.1l0.001 0.005c-0.031-0.129-0.093-0.242-0.177-0.333l0 0.001-0.004-0.007-5-5c-0.136-0.137-0.325-0.222-0.533-0.222-0.415 0-0.751 0.336-0.751 0.751 0 0.208 0.085 0.396 0.221 0.532l3.721 3.72h-24.379l3.72-3.72c0.131-0.135 0.212-0.319 0.212-0.523 0-0.414-0.336-0.75-0.75-0.75-0.203 0-0.388 0.081-0.523 0.213l-5 5c-0.067 0.067-0.122 0.147-0.159 0.236l-0.002 0.005-0.002 0.002-0.001 0.013c-0.034 0.081-0.054 0.175-0.055 0.274v0c0.005 0.037 0.012 0.069 0.020 0.101l-0.001-0.005c0.005 0.037 0.012 0.069 0.021 0.101l-0.001-0.005c0.032 0.132 0.095 0.247 0.181 0.339l-0-0 5 5.001c0.136 0.134 0.322 0.218 0.528 0.218 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.083-0.394-0.218-0.529l-3.72-3.719h24.378l-3.719 3.719c-0.136 0.136-0.22 0.324-0.22 0.531 0 0.415 0.336 0.751 0.751 0.751 0.207 0 0.395-0.084 0.531-0.22l5.002-5.005c0.066-0.066 0.119-0.145 0.157-0.232l0.002-0.005z"></path>
                                            </svg>
                                            <span>Max</span>10.00 TWT per USDT</p>
                                    </NavLink>
                                </div>
                                <div className='rowBoxItems'>
                                    <NavLink to="/nftliquidity">
                                        <div className="topheadTtl">
                                            <h2 className="imgBx">
                                                <img src={twtImg} alt="TWT" />
                                                <img src={usdtImg} alt="USDT" />
                                                <strong>OC/MATIC</strong>
                                            </h2>
                                            <span>In Rage</span>
                                        </div>
                                        <p><span>Min:</span>0.500 TWT per USDT
                                            <svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>arrow-left-right</title>
                                                <path d="M30.693 16.287l0.001-0.013c0.034-0.081 0.054-0.175 0.055-0.274v-0c-0.005-0.037-0.012-0.070-0.021-0.101l0.001 0.005c-0.005-0.036-0.012-0.069-0.020-0.1l0.001 0.005c-0.031-0.129-0.093-0.242-0.177-0.333l0 0.001-0.004-0.007-5-5c-0.136-0.137-0.325-0.222-0.533-0.222-0.415 0-0.751 0.336-0.751 0.751 0 0.208 0.085 0.396 0.221 0.532l3.721 3.72h-24.379l3.72-3.72c0.131-0.135 0.212-0.319 0.212-0.523 0-0.414-0.336-0.75-0.75-0.75-0.203 0-0.388 0.081-0.523 0.213l-5 5c-0.067 0.067-0.122 0.147-0.159 0.236l-0.002 0.005-0.002 0.002-0.001 0.013c-0.034 0.081-0.054 0.175-0.055 0.274v0c0.005 0.037 0.012 0.069 0.020 0.101l-0.001-0.005c0.005 0.037 0.012 0.069 0.021 0.101l-0.001-0.005c0.032 0.132 0.095 0.247 0.181 0.339l-0-0 5 5.001c0.136 0.134 0.322 0.218 0.528 0.218 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.083-0.394-0.218-0.529l-3.72-3.719h24.378l-3.719 3.719c-0.136 0.136-0.22 0.324-0.22 0.531 0 0.415 0.336 0.751 0.751 0.751 0.207 0 0.395-0.084 0.531-0.22l5.002-5.005c0.066-0.066 0.119-0.145 0.157-0.232l0.002-0.005z"></path>
                                            </svg>
                                            <span>Max</span>10.00 TWT per USDT</p>
                                    </NavLink>
                                </div>
                                <div className='rowBoxItems'>
                                    <NavLink to="/nftliquidity">
                                        <div className="topheadTtl">
                                            <h2 className="imgBx">
                                                <img src={twtImg} alt="TWT" />
                                                <img src={usdtImg} alt="USDT" />
                                                <strong>OC/MATIC</strong>
                                            </h2>
                                            <span>In Rage</span>
                                        </div>
                                        <p><span>Min:</span>0.500 TWT per USDT
                                            <svg fill="#000000" width="800px" height="800px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                                <title>arrow-left-right</title>
                                                <path d="M30.693 16.287l0.001-0.013c0.034-0.081 0.054-0.175 0.055-0.274v-0c-0.005-0.037-0.012-0.070-0.021-0.101l0.001 0.005c-0.005-0.036-0.012-0.069-0.020-0.1l0.001 0.005c-0.031-0.129-0.093-0.242-0.177-0.333l0 0.001-0.004-0.007-5-5c-0.136-0.137-0.325-0.222-0.533-0.222-0.415 0-0.751 0.336-0.751 0.751 0 0.208 0.085 0.396 0.221 0.532l3.721 3.72h-24.379l3.72-3.72c0.131-0.135 0.212-0.319 0.212-0.523 0-0.414-0.336-0.75-0.75-0.75-0.203 0-0.388 0.081-0.523 0.213l-5 5c-0.067 0.067-0.122 0.147-0.159 0.236l-0.002 0.005-0.002 0.002-0.001 0.013c-0.034 0.081-0.054 0.175-0.055 0.274v0c0.005 0.037 0.012 0.069 0.020 0.101l-0.001-0.005c0.005 0.037 0.012 0.069 0.021 0.101l-0.001-0.005c0.032 0.132 0.095 0.247 0.181 0.339l-0-0 5 5.001c0.136 0.134 0.322 0.218 0.528 0.218 0.415 0 0.751-0.336 0.751-0.751 0-0.207-0.083-0.394-0.218-0.529l-3.72-3.719h24.378l-3.719 3.719c-0.136 0.136-0.22 0.324-0.22 0.531 0 0.415 0.336 0.751 0.751 0.751 0.207 0 0.395-0.084 0.531-0.22l5.002-5.005c0.066-0.066 0.119-0.145 0.157-0.232l0.002-0.005z"></path>
                                            </svg>
                                            <span>Max</span>10.00 TWT per USDT</p>
                                    </NavLink>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PoolCom