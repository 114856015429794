
import useActiveWeb3React from '../../hooks/useWeb3'

import { useSwapCallArguments } from '../../hooks/useSwapCallArguments'
import { useMemo } from 'react'

import useSendSwapTransaction from './useSendSwapTransaction'
export const SwapCallbackState = {
    INVALID: 0,
    LOADING: 1,
    VALID: 2,
}


// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback({
    trade,
    allowedSlippage,
    recipientAddressOrName,
    signatureData,
    deadline,
    feeOptions,
}) {
    const { account, chainId, library } = useActiveWeb3React()

    const swapCalls = useSwapCallArguments(
        trade,
        allowedSlippage,
        recipientAddressOrName,
        signatureData,
        deadline,
        feeOptions
    )
    const { callback } = useSendSwapTransaction(account, chainId, library, trade, swapCalls)
    const recipient = recipientAddressOrName === null ? account : recipientAddressOrName

    return useMemo(() => {
        if (!trade || !library || !account || !chainId || !callback) {
            // console.log("error here")
            return { state: SwapCallbackState.INVALID, callback: null, error: <>Missing dependencies</> }
        }
        if (!recipient) {
            // console.log("why error here", recipient)
            if (recipientAddressOrName !== null) {
                // console.log("error here on why again")
                return { state: SwapCallbackState.INVALID, callback: null, error: <>Invalid recipient</> }
            } else {
                // console.log("error here why")
                return { state: SwapCallbackState.LOADING, callback: null, error: null }
            }
        }
        return {
            state: SwapCallbackState.VALID,
            callback: async function onSwap() {
                return callback().then((response) => {
                    return response
                })
            },
            error: null,
        }
    }, [trade, library, account, chainId, callback, recipient, recipientAddressOrName])
}
