import PropTypes from "prop-types";

import {Bound} from "../../state/pool/config";
import React from "react";
import {StepCounter} from "./StepCounter";

export const RangeSelector = ({
  priceLower,
  priceUpper,
  getDecrementLower,
  getIncrementLower,
  getDecrementUpper,
  getIncrementUpper,
  onLeftRangeInput,
  onRightRangeInput,
  currencyA,
  currencyB,
  feeAmount,
  ticksAtLimit
}) => {

    const tokenA = (currencyA ?? undefined)?.wrapped
    const tokenB = (currencyB ?? undefined)?.wrapped
    const isSorted = tokenA && tokenB && tokenA.sortsBefore(tokenB)

    const leftPrice = isSorted ? priceLower : priceUpper?.invert()
    const rightPrice = isSorted ? priceUpper : priceLower?.invert()

    return (
        <>
            <div className="minmxPrcInr">
                <StepCounter
                    value={ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER] ? '0' : leftPrice?.toSignificant(5) ?? ''}
                    onUserInput={onLeftRangeInput}
                    width="48%"
                    decrement={isSorted ? getDecrementLower : getIncrementUpper}
                    increment={isSorted ? getIncrementLower : getDecrementUpper}
                    decrementDisabled={ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER]}
                    incrementDisabled={ticksAtLimit[isSorted ? Bound.LOWER : Bound.UPPER]}
                    feeAmount={feeAmount}
                    label={leftPrice ? `${currencyB?.symbol}` : '-'}
                    title="Min Price"
                    tokenA={currencyA?.symbol}
                    tokenB={currencyB?.symbol}
                />
            </div>
            <div className="minmxPrcInr">
                <StepCounter
                    value={ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER] ? '∞' : rightPrice?.toSignificant(5) ?? ''}
                    onUserInput={onRightRangeInput}
                    width="48%"
                    decrement={isSorted ? getDecrementUpper : getIncrementLower}
                    increment={isSorted ? getIncrementUpper : getDecrementLower}
                    incrementDisabled={ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER]}
                    decrementDisabled={ticksAtLimit[isSorted ? Bound.UPPER : Bound.LOWER]}
                    feeAmount={feeAmount}
                    label={rightPrice ? `${currencyB?.symbol}` : '-'}
                    tokenA={currencyA?.symbol}
                    tokenB={currencyB?.symbol}
                    title="Max Price"
                />
            </div>
        </>
    )
}

RangeSelector.propTypes = {
    priceLower: PropTypes.number,
    priceUpper: PropTypes.number,
    getDecrementLower: PropTypes.func,
    getIncrementLower: PropTypes.func,
    getDecrementUpper: PropTypes.func,
    getIncrementUpper: PropTypes.func,
    onLeftRangeInput: PropTypes.func,
    onRightRangeInput: PropTypes.func,
    currencyA: PropTypes.string,
    currencyB: PropTypes.string,
    feeAmount: PropTypes.number,
    ticksAtLimit: PropTypes.bool
};