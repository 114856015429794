
import { TradeType } from '@uniswap/sdk-core'
import useAutoSlippageTolerance from '../../hooks/useAutoSlippageTolerance'
import { useBestTrade } from '../../hooks/useBestTrade'
import tryParseCurrencyAmount from '../../utils/tryParseCurrencyAmount'
import { useCallback, useEffect, useMemo } from 'react'
import { useUserSlippageToleranceWithDefault } from '../account/hooks'

import { useCurrency } from '../../hooks/Tokens'

import useParsedQueryString from '../../hooks/useParsedQueryString'
import { isAddress } from '../../utils'
import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { useDispatch, useSelector } from "../../redux/store";
import { useCurrencyBalances } from "../../hooks/useTokenBalance";
import useActiveWeb3React from "../../hooks/useWeb3";
import { OCToken, USDT } from '../../config/tokens'

export function useSwapState() {
    return useSelector((state) => state.swap)
}

export function useSwapActionHandlers() {
    const dispatch = useDispatch()
    const onCurrencySelection = useCallback(
        (field, currency) => {
            dispatch(
                selectCurrency({
                    field,
                    currencyId: currency?.isToken ? currency?.address : currency?.isNative ? 'MATIC' : '',
                })
            )
        },
        [dispatch]
    )

    const onSwitchTokens = useCallback(() => {
        dispatch(switchCurrencies())
    }, [dispatch])

    const onUserInput = useCallback(
        (field, typedValue) => {
            dispatch(typeInput({ field, typedValue }))
        },
        [dispatch]
    )

    const onChangeRecipient = useCallback(
        (recipient) => {
            dispatch(setRecipient({ recipient }))
        },
        [dispatch]
    )

    return {
        onSwitchTokens,
        onCurrencySelection,
        onUserInput,
        onChangeRecipient,
    }
}

const BAD_RECIPIENT_ADDRESSES = {
    '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f': true, // v2 factory
    '0xf164fC0Ec4E93095b804a4795bBe1e041497b92a': true, // v2 router 01
    '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D': true, // v2 router 02
}

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo() {
    const { account } = useActiveWeb3React()

    const {
        independentField,
        typedValue,
        [Field.INPUT]: { currencyId: inputCurrencyId },
        [Field.OUTPUT]: { currencyId: outputCurrencyId },
        recipient,
    } = useSwapState()

    const inputCurrency = useCurrency(inputCurrencyId)
    const outputCurrency = useCurrency(outputCurrencyId)
    const recipientLookup = undefined // useENSRecipient(recipient)
    const to = (recipient === null ? account : recipientLookup?.address) ?? null

    const relevantTokenBalances = useCurrencyBalances(
        account ?? undefined,
        useMemo(() => [inputCurrency ?? undefined, outputCurrency ?? undefined], [inputCurrency, outputCurrency])
    )

    const isExactIn = independentField === Field.INPUT
    const parsedAmount = useMemo(
        () => tryParseCurrencyAmount(typedValue, (isExactIn ? inputCurrency : outputCurrency) ?? undefined),
        [inputCurrency, isExactIn, outputCurrency, typedValue]
    )

    // console.log(isExactIn, parsedAmount?.toSignificant(6), outputCurrency)
    const trade = useBestTrade(
        isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
        parsedAmount,
        (isExactIn ? outputCurrency : inputCurrency) ?? undefined
    )

    const currencyBalances = useMemo(
        () => ({
            [Field.INPUT]: relevantTokenBalances[0],
            [Field.OUTPUT]: relevantTokenBalances[1],
        }),
        [relevantTokenBalances]
    )

    const currencies = useMemo(
        () => ({
            [Field.INPUT]: inputCurrency,
            [Field.OUTPUT]: outputCurrency,
        }),
        [inputCurrency, outputCurrency]
    )

    // allowed slippage is either auto slippage, or custom user defined slippage if auto slippage disabled
    const autoSlippageTolerance = useAutoSlippageTolerance(trade.trade)
    const allowedSlippage = useUserSlippageToleranceWithDefault(autoSlippageTolerance)

    const inputError = useMemo(() => {
        let inputError

        if (!account) {
            inputError = "Connect Wallet"
        }

        if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
            inputError = inputError ?? "Select a token"
        }

        if (!parsedAmount) {
            inputError = inputError ?? "Enter an amount"
        }

        const formattedTo = isAddress(to)
        if (!to || !formattedTo) {
            inputError = inputError ?? "Enter a recipient"
        } else {
            if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
                inputError = inputError ?? "Invalid recipient"
            }
        }

        // compare input balance to max input based on version
        const [balanceIn, amountIn] = [currencyBalances[Field.INPUT], trade.trade?.maximumAmountIn(allowedSlippage)]

        if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
            inputError = `Insufficient ${amountIn.currency.symbol} balance`
        }

        return inputError
    }, [account, allowedSlippage, currencies, currencyBalances, parsedAmount, to, trade.trade])

    return useMemo(
        () => ({
            currencies,
            currencyBalances,
            parsedAmount,
            inputError,
            trade,
            allowedSlippage,
        }),
        [allowedSlippage, currencies, currencyBalances, inputError, parsedAmount, trade]
    )
}

function parseCurrencyFromURLParameter(urlParam) {
    if (typeof urlParam === 'string') {
        const valid = isAddress(urlParam)
        if (valid) return valid
        const upper = urlParam.toUpperCase()
        if (upper === 'MATIC') return 'MATIC'
    }
    return ''
}

function parseTokenAmountURLParameter(urlParam) {
    return typeof urlParam === 'string' && !isNaN(parseFloat(urlParam)) ? urlParam : ''
}

function parseIndependentFieldURLParameter(urlParam) {
    return typeof urlParam === 'string' && urlParam.toLowerCase() === 'output' ? Field.OUTPUT : Field.INPUT
}

const ENS_NAME_REGEX = /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/
function validatedRecipient(recipient) {
    if (typeof recipient !== 'string') return null
    const address = isAddress(recipient)
    if (address) return address
    if (ENS_NAME_REGEX.test(recipient)) return recipient
    if (ADDRESS_REGEX.test(recipient)) return recipient
    return null
}

export function queryParametersToSwapState(parsedQs) {
    let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency ?? USDT.address)
    let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency ?? OCToken.address)
    const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount)
    const independentField = parseIndependentFieldURLParameter(parsedQs.exactField)
    if (inputCurrency === '' && outputCurrency === '' && typedValue === '' && independentField === Field.INPUT) {
        // Defaults to having the native currency selected
        inputCurrency = 'MATIC'
    } else if (inputCurrency === outputCurrency) {
        // clear output if identical
        outputCurrency = ''
    }

    const recipient = validatedRecipient(parsedQs.recipient)

    return {
        [Field.INPUT]: {
            currencyId: inputCurrency === '' ? null : inputCurrency ?? null,
        },
        [Field.OUTPUT]: {
            currencyId: outputCurrency === '' ? null : outputCurrency ?? null,
        },
        typedValue,
        independentField,
        recipient,
    }
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch() {
    const { chainId } = useActiveWeb3React()
    const dispatch = useDispatch()
    const parsedQs = useParsedQueryString()

    const parsedSwapState = useMemo(() => {
        return queryParametersToSwapState(parsedQs)
    }, [parsedQs])

    useEffect(() => {
        if (!chainId) return
        const inputCurrencyId = parsedSwapState[Field.INPUT].currencyId ?? undefined
        const outputCurrencyId = parsedSwapState[Field.OUTPUT].currencyId ?? undefined

        dispatch(
            replaceSwapState({
                typedValue: parsedSwapState.typedValue,
                field: parsedSwapState.independentField,
                inputCurrencyId,
                outputCurrencyId,
                recipient: parsedSwapState.recipient,
            })
        )
    }, [dispatch, chainId, parsedSwapState])

    return parsedSwapState
}
