import React, {useMemo} from "react";
import {useTokenImage} from "../../hooks/useTokenImage";
import useCurrencyBalance from "../../hooks/useTokenBalance";

export const InputPanel = ({
    currency,
    onUserInput,
    onMaxSelect,
    value,
    showMaxButton,
    locked
}) => {
    const imageURL = useTokenImage(useMemo(() => currency, [currency]))
    const selectedCurrencyBalance = useCurrencyBalance(currency ?? undefined)

    return (
        <>
            <input type="text" disabled={locked} value={value} onInput={(e) => { onUserInput(e.target.value) }} placeholder="0.0" />
            <div className="rightBx">
                <button className="coinbx"><img src={imageURL} alt="usdt icon" />{ currency?.symbol }</button>
                <span>Balance: {selectedCurrencyBalance?.toExact(4)}</span>
                { showMaxButton && <button className="maxBtn" onClick={onMaxSelect}>MAX</button> }
            </div>
        </>
    )
}