import { useContract } from "../../../hooks/useContract"
import useActiveWeb3React from "../../../hooks/useWeb3"

import PoolABI from "../../../abi/poolABI.json"
import { useCallback } from "react"
import { ethers } from "ethers"
import { showToast } from "../../../utils"

export const useStake = ({ address }) => {
    const { account } = useActiveWeb3React()
    const poolInstance = useContract(address, PoolABI)

    const stake = useCallback(async (input) => {
        return new Promise(async (resolve, reject) => {
            if (account) {
                try {
                    const tx = await poolInstance?.joinPool(ethers.utils.parseEther(input))
                    await showToast({
                        type: "promise",
                        promise: tx.wait(),
                        loading: "Staking...",
                        success: "Staked successfully!"
                    })
                    resolve(true)
                } catch (err) {
                    showToast({
                        message: err.reason ? err.reason : (err.data ? err.data.message : err.message),
                        type: "error"
                    })
                    resolve(false)
                }
            }
        })
    }, [poolInstance, account])

    const unstakeRequest = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            if (account) {
                try {
                    const tx = await poolInstance?.requestUnstake()
                    await showToast({
                        type: "promise",
                        promise: tx.wait(),
                        loading: "Requesting...",
                        success: "Requested successfully!"
                    })
                    resolve(true)
                } catch (err) {
                    showToast({
                        message: err.reason ? err.reason : (err.data ? err.data.message : err.message),
                        type: "error"
                    })
                    resolve(false)
                }
            }
        })
    }, [poolInstance, account])

    const unstake = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            if (account) {
                try {
                    const tx = await poolInstance?.unstake()
                    await showToast({
                        type: "promise",
                        promise: tx.wait(),
                        loading: "Unstaking...",
                        success: "Unstaked successfully!"
                    })
                    resolve(true)
                } catch (err) {
                    showToast({
                        message: err.reason ? err.reason : (err.data ? err.data.message : err.message),
                        type: "error"
                    })
                    resolve(false)
                }
            }
        })
    }, [poolInstance, account])

    const restake = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            if (account) {
                try {
                    const tx = await poolInstance?.restake()
                    await showToast({
                        type: "promise",
                        promise: tx.wait(),
                        loading: "Restaking...",
                        success: "Restaked successfully!"
                    })
                    resolve(true)
                } catch (err) {
                    showToast({
                        message: err.reason ? err.reason : (err.data ? err.data.message : err.message),
                        type: "error"
                    })
                    resolve(false)
                }
            }
        })
    }, [poolInstance, account])

    const compound = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            if (account) {
                try {
                    const tx = await poolInstance?.compound()
                    await showToast({
                        type: "promise",
                        promise: tx.wait(),
                        loading: "Compounding...",
                        success: "Compounded successfully!"
                    })
                    resolve(true)
                } catch (err) {
                    showToast({
                        message: err.reason ? err.reason : (err.data ? err.data.message : err.message),
                        type: "error"
                    })
                    resolve(false)
                }
            }
        })
    }, [poolInstance, account])

    const harvestOG = useCallback(async () => {
        return new Promise(async (resolve, reject) => {
            if (account) {
                try {
                    const tx = await poolInstance?.claimOG()
                    await showToast({
                        type: "promise",
                        promise: tx.wait(),
                        loading: "Harvesting...",
                        success: "Harvested OG successfully!"
                    })
                    resolve(true)
                } catch (err) {
                    showToast({
                        message: err.reason ? err.reason : (err.data ? err.data.message : err.message),
                        type: "error"
                    })
                    resolve(false)
                }
            }
        })
    }, [poolInstance, account])

    return {
        stake,
        unstakeRequest,
        unstake: unstakeRequest,
        harvestOG,
        restake,
        compound
    }
}