export const getMaticPrice = async () => {
    try {
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd');
        const data = await response.json();
        return data['matic-network']['usd'];
    } catch(err) {
        console.log(err)
        return 0
    }
}

export const getOCPrice = async () => {
    try {
        const response = await fetch('https://oc-price-api.vercel.app/oc-price');
        const data = await response.json();
        return data['price'];
    } catch(err) {
        console.log(err)
        return 0
    }
}