
import {useNative} from './Tokens'
import tryParseCurrencyAmount from '../utils/tryParseCurrencyAmount'
import { useMemo, useState } from 'react'

import { WRAPPED_NATIVE_CURRENCY } from '../config/tokens'
import { useWETHContract } from './useContract'
import useCurrencyBalance from "./useTokenBalance";
import {showToast} from "../utils";
import useActiveWeb3React from "./useWeb3";

export const WrapType = {
    NOT_APPLICABLE: 0,
    WRAP: 1,
    UNWRAP: 2
}

const NOT_APPLICABLE = { wrapType: WrapType.NOT_APPLICABLE }

const WrapInputError = {
    NO_ERROR: 0, // must be equal to 0 so all other errors are truthy
    ENTER_NATIVE_AMOUNT: 1,
    ENTER_WRAPPED_AMOUNT: 2,
    INSUFFICIENT_NATIVE_BALANCE: 3,
    INSUFFICIENT_WRAPPED_BALANCE: 4,
}

export function WrapErrorText({ wrapInputError }) {
    const native = useNative()
    const wrapped = native?.wrapped

    switch (wrapInputError) {
        case WrapInputError.NO_ERROR:
            return null
        case WrapInputError.ENTER_NATIVE_AMOUNT:
            return `Enter ${native?.symbol} amount`
        case WrapInputError.ENTER_WRAPPED_AMOUNT:
            return `Enter ${wrapped?.symbol} amount`

        case WrapInputError.INSUFFICIENT_NATIVE_BALANCE:
            return `Insufficient ${native?.symbol} balance`
        case WrapInputError.INSUFFICIENT_WRAPPED_BALANCE:
            return `Insufficient ${wrapped?.symbol} balance`
        default:
            return null
    }
}

export default function useWrapCallback(
    inputCurrency,
    outputCurrency,
    typedValue
) {
    const { chainId } = useActiveWeb3React()
    const wethContract = useWETHContract()
    const balance = useCurrencyBalance(inputCurrency ?? undefined)
    // we can always parse the amount typed as the input currency, since wrapping is 1:1
    const inputAmount = useMemo(
        () => tryParseCurrencyAmount(typedValue, inputCurrency ?? undefined),
        [inputCurrency, typedValue]
    )

    // This allows an async error to propagate within the React lifecycle.
    // Without rethrowing it here, it would not show up in the UI - only the dev console.
    const [error, setError] = useState()
    if (error) throw error

    return useMemo(() => {
        if (!wethContract || !chainId || !inputCurrency || !outputCurrency) return NOT_APPLICABLE
        const weth = WRAPPED_NATIVE_CURRENCY(chainId)
        if (!weth) return NOT_APPLICABLE

        const hasInputAmount = Boolean(inputAmount?.greaterThan('0'))
        const sufficientBalance = inputAmount && balance && !balance.lessThan(inputAmount)

        if (inputCurrency.isNative && weth.equals(outputCurrency)) {
            return {
                wrapType: WrapType.WRAP,
                execute:
                    sufficientBalance && inputAmount
                        ? async () => {
                            try {
                                const network = await wethContract.provider.getNetwork()
                                console.log(network.chainId !== chainId, wethContract.address.toLowerCase() !== WRAPPED_NATIVE_CURRENCY[network.chainId]?.address.toLowerCase())
                                if (
                                    network.chainId !== chainId ||
                                    wethContract.address.toLowerCase() !== WRAPPED_NATIVE_CURRENCY[network.chainId]?.address.toLowerCase()
                                ) {
                                    // const error = new Error(`Invalid Wrap Called`)
                                    // setError(error)
                                    // throw error
                                }
                                const txReceipt = await wethContract.deposit({ value: `0x${inputAmount.quotient.toString(16)}` })
                                await showToast({
                                    message: 'Deposit success',
                                    type: 'promise',
                                    promise: txReceipt.wait(),
                                    success: 'Deposit success',
                                    pending: 'Deposit in pending',
                                })
                            } catch (error) {
                                console.error('Could not deposit', error)
                            }
                        }
                        : undefined,
                inputError: sufficientBalance
                    ? undefined
                    : hasInputAmount
                        ? WrapInputError.INSUFFICIENT_NATIVE_BALANCE
                        : WrapInputError.ENTER_NATIVE_AMOUNT,
            }
        } else if (weth.equals(inputCurrency) && outputCurrency.isNative) {
            return {
                wrapType: WrapType.UNWRAP,
                execute:
                    sufficientBalance && inputAmount
                        ? async () => {
                            try {
                                const txReceipt = await wethContract.withdraw(`0x${inputAmount.quotient.toString(16)}`)
                                await showToast({
                                    type: 'promise',
                                    promise: txReceipt.wait(),
                                    success: 'Withdraw success',
                                    pending: 'Withdraw in pending',
                                })
                            } catch (error) {
                                console.error('Could not withdraw', error)
                            }
                        }
                        : undefined,
                inputError: sufficientBalance
                    ? undefined
                    : hasInputAmount
                        ? WrapInputError.INSUFFICIENT_WRAPPED_BALANCE
                        : WrapInputError.ENTER_WRAPPED_AMOUNT,
            }
        } else {
            return NOT_APPLICABLE
        }
    }, [wethContract, chainId, inputCurrency, outputCurrency, inputAmount, balance])
}
