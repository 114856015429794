import PropTypes from "prop-types";
import React, {useCallback, useEffect, useState} from "react";

export const StepCounter = ({
    value,
    decrement,
    increment,
    decrementDisabled = false,
    incrementDisabled = false,
    width,
    locked,
    onUserInput,
    title,
    tokenA,
    tokenB,
}) => {

    // const [active, setActive] = useState(false)

    // let user type value and only update parent value on blur
    const [localValue, setLocalValue] = useState('')
    const [useLocalValue, setUseLocalValue] = useState(false)

    // animation if parent value updates local value
    // const [pulsing, setPulsing] = useState(false)
    //
    const handleOnFocus = () => {
        setUseLocalValue(true)
        // setActive(true)
    }
    //
    const handleOnBlur = useCallback(() => {
        setUseLocalValue(false)
        onUserInput(localValue) // trigger update on parent value
    }, [localValue, onUserInput])

    // for button clicks
    const handleDecrement = useCallback(() => {
        setUseLocalValue(false)
        console.log("decrement called")
        onUserInput(decrement())
    }, [decrement, onUserInput])

    const handleIncrement = useCallback(() => {
        setUseLocalValue(false)
        onUserInput(increment())
    }, [increment, onUserInput])
    //
    // useEffect(() => {
    //     if (localValue !== value && !useLocalValue) {
    //         setTimeout(() => {
    //             setLocalValue(value) // reset local value to match parent
    //             setPulsing(true) // trigger animation
    //             setTimeout(function () {
    //                 setPulsing(false)
    //             }, 1800)
    //         }, 0)
    //     }
    // }, [localValue, useLocalValue, value])

    useEffect(() => {
        if (localValue !== value && !useLocalValue) {
            setTimeout(() => {
                setLocalValue(value) // reset local value to match parent
                // setPulsing(true) // trigger animation
                // setTimeout(function () {
                //     setPulsing(false)
                // }, 1800)
            }, 0)
        }
    }, [localValue, useLocalValue, value])

    return (
        <>
            <p>{title}</p>
            <div className="inptBox">
                {!locked && <button disabled={decrementDisabled} onClick={handleDecrement}>-</button> }

                <input placeholder={title} onFocus={handleOnFocus} onBlur={handleOnBlur} disabled={locked} value={localValue} onInput={(e) => {
                    setLocalValue(e.target.value)
                }} min={0} />


                {!locked && <button onClick={handleIncrement} disabled={incrementDisabled}>+</button> }
            </div>
            <p>
                {tokenB} per {tokenA}
            </p>
        </>
    )
}

StepCounter.propTypes = {
    value: PropTypes.number.isRequired,
    onUserInput: PropTypes.func.isRequired,
    width: PropTypes.string,
    decrement: PropTypes.func.isRequired,
    increment: PropTypes.func.isRequired,
    decrementDisabled: PropTypes.bool.isRequired,
    incrementDisabled: PropTypes.bool.isRequired,
    feeAmount: PropTypes.string,
    label: PropTypes.string,
    title: PropTypes.string,
    tokenA: PropTypes.string,
    tokenB: PropTypes.string
}