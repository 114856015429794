import { Token } from "../config/tokens";
import { BASE_URL, defaultTokenImage } from "../config";
import { useEffect, useMemo, useState } from "react";
import { fetchURL } from "../utils/fetchURL";
import { useWrappedToken } from "./useWrappedToken";

export const useTokenImage = (token) => {
    const [imageURL, setImageURL] = useState(defaultTokenImage)
    const wToken = useWrappedToken(token) ?? token

    useEffect(() => {
        (
            async () => {
                const gitImage = `https://raw.githubusercontent.com/Uniswap/assets/master/blockchains/polygon/assets/${wToken?.address}/logo.png`
                const _imageUrl = `${BASE_URL}tokens/${wToken?.address}/logo.png`
                const isFileExists = await fetchURL(_imageUrl)

                if (isFileExists) {
                    setImageURL(_imageUrl)
                } else {
                    const _imageUrl = `${BASE_URL}tokens/${wToken?.address}/logo.svg`
                    const isSvgFileExists = await fetchURL(_imageUrl)
                    if (isSvgFileExists) {
                        setImageURL(_imageUrl)
                    } else {
                        const isFileExistsOnRemote = await fetchURL(gitImage)
                        if (isFileExistsOnRemote) {
                            setImageURL(gitImage)
                        }
                    }
                }
            }
        )()
    }, [wToken, setImageURL, token])

    return useMemo(() => imageURL, [imageURL])
}