import { ApprovalState } from '../hooks/useApproval'

export const SwapRouterVersion = {
    V2: 0,
    V3: 1,
    V2V3: 2,
}

export function getTxOptimizedSwapRouter({
    onlyV2Routes,
    onlyV3Routes,
    tradeHasSplits,
    approvalStates,
}) {
    if ([approvalStates.v2, approvalStates.v3, approvalStates.v2V3].includes(ApprovalState.PENDING)) return undefined
    if (approvalStates.v2V3 === ApprovalState.APPROVED) return SwapRouterVersion.V2V3
    if (approvalStates.v2 === ApprovalState.APPROVED && onlyV2Routes && !tradeHasSplits) return SwapRouterVersion.V2
    if (approvalStates.v3 === ApprovalState.APPROVED && onlyV3Routes) return SwapRouterVersion.V3
    return SwapRouterVersion.V2V3
}
