import { Position } from '@uniswap/v3-sdk'
import { usePool } from './usePool'

import { useCurrency } from './Tokens'

export function useDerivedPositionInfo(positionDetails) {
    const currency0 = useCurrency(positionDetails?.token0)
    const currency1 = useCurrency(positionDetails?.token1)

    // construct pool data
    const [, pool] = usePool(currency0 ?? undefined, currency1 ?? undefined, positionDetails?.fee)

    let position = undefined
    if (pool && positionDetails) {
        position = new Position({
            pool,
            liquidity: positionDetails.liquidity.toString(),
            tickLower: positionDetails.tickLower,
            tickUpper: positionDetails.tickUpper,
        })
    }

    return {
        position,
        pool: pool ?? undefined,
    }
}
