import { useMemo } from 'react'

export function useIsSwapUnsupported(currencyIn?, currencyOut?){
    const unsupportedTokens = false
    return useMemo(() => {
        if (!unsupportedTokens) {
            return false
        }
        const currencyInUnsupported = Boolean(currencyIn?.isToken && unsupportedTokens[currencyIn.address])
        const currencyOutUnsupported = Boolean(currencyOut?.isToken && unsupportedTokens[currencyOut.address])
        return currencyInUnsupported || currencyOutUnsupported
    }, [currencyIn, currencyOut, unsupportedTokens])
}
