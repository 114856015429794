import { useMemo } from 'react'
import { calculateGasMargin } from '../../utils/calculateGasMargin'
import isZero from '../../utils/isZero'
import { swapErrorToUserReadableMessage } from '../../utils/swapErrorToUserReadableMessage'

// returns a function that will execute a swap, if the parameters are all valid
export default function useSendSwapTransaction(
    account,
    chainId,
    library,
    trade,
    swapCalls
) {
    return useMemo(() => {
        if (!trade || !library || !account || !chainId) {
            return { callback: null }
        }
        return {
            callback: async function onSwap() {
                const estimatedCalls = await Promise.all(
                    swapCalls.map((call) => {
                        const { address, calldata, value } = call

                        const tx =
                            !value || isZero(value)
                                ? { from: account, to: address, data: calldata }
                                : {
                                    from: account,
                                    to: address,
                                    data: calldata,
                                    value,
                                }

                        return library
                            .estimateGas(tx)
                            .then((gasEstimate) => {
                                return {
                                    call,
                                    gasEstimate,
                                }
                            })
                            .catch((gasError) => {
                                console.debug('Gas estimate failed, trying eth_call to extract error', call)

                                return library
                                    .call(tx)
                                    .then((result) => {
                                        console.debug('Unexpected successful call after failed estimate gas', call, gasError, result)
                                        return { call, error: <>Unexpected issue with estimating the gas. Please try again.</> }
                                    })
                                    .catch((callError) => {
                                        console.debug('Call threw error', call, callError)
                                        return { call, error: swapErrorToUserReadableMessage(callError) }
                                    })
                            })
                    })
                )

                console.log("swapCalls", swapCalls)

                // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
                let bestCallOption= estimatedCalls.find(
                    (el, ix, list) =>
                    'gasEstimate' in el && (ix === list.length - 1 || 'gasEstimate' in list[ix + 1])
                )

                // check if any calls errored with a recognizable error
                if (!bestCallOption) {
                    const errorCalls = estimatedCalls.filter((call) => 'error' in call)
                    if (errorCalls.length > 0) throw errorCalls[errorCalls.length - 1].error
                    const firstNoErrorCall = estimatedCalls.find(
                        (call) => !('error' in call)
                    )
                    console.log(estimatedCalls)

                    if (!firstNoErrorCall) throw new Error(`Unexpected error. Could not estimate gas for the swap.`)
                    bestCallOption = firstNoErrorCall
                }

                const {
                    call: { address, calldata, value },
                } = bestCallOption

                return library
                    .getSigner()
                    .sendTransaction({
                        from: account,
                        to: address,
                        data: calldata,
                        // let the wallet try if we can't estimate the gas
                        ...('gasEstimate' in bestCallOption ? { gasLimit: calculateGasMargin(bestCallOption.gasEstimate) } : {}),
                        ...(value && !isZero(value) ? { value } : {}),
                    })
                    .then((response) => {
                        return response
                    })
                    .catch((error) => {
                        // if the user rejected the tx, pass this along
                        if (error?.code === 4001) {
                            throw new Error(`Transaction rejected.`)
                        } else {
                            // otherwise, the error was unexpected and we need to convey that
                            console.error(`Swap failed`, error, address, calldata, value)

                            throw new Error(`Swap failed: ${swapErrorToUserReadableMessage(error)}`)
                        }
                    })
            },
        }
    }, [account, chainId, library, swapCalls, trade])
}
