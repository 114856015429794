import { useContract } from "../../../hooks/useContract"
import useActiveWeb3React from "../../../hooks/useWeb3"

import PoolABI from "../../../abi/poolABI.json"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ethers } from "ethers"
import { showErrorToast, showPromise } from "../../../utils/pageHelpers"

export const useClaimInfo = ({address}) => {
    const [claimRewards, setClaimRewards] = useState(0)
    const [claimPeriod, setClaimPeriod] = useState(0)
    const { account } = useActiveWeb3React()

    const poolInstance = useContract( address, PoolABI )
    const fetchClaimInfo = useCallback(async () => {
        try {
            const userInfo = await poolInstance.stakers( account )
            const uCount =  userInfo.claimCount
            
            if ( parseFloat(uCount) > 0 ) {
                const uInfo = await poolInstance.claimRequests( account, uCount-1 )
                if ( uInfo.isProcessed === 1 ) {
                    setClaimRewards( parseFloat(ethers.utils.formatEther(uInfo.amount)) )
                    setClaimPeriod( uInfo.timestamp )
                } else {
                    setClaimRewards( 0 )
                    setClaimPeriod( 0 )
                }
            }
        } catch(err) {
            console.error(err)
        }
    }, [poolInstance, account])

    const claim = useCallback(async () => {
        try {
            const txn = await poolInstance.claim()
            await showPromise(txn.wait(), "Claiming rewards", "Rewards claim failed", "Rewards claimed successfully")
            fetchClaimInfo()
        } catch(err) {
            console.error(err)
            showErrorToast(
                err.reason ? err.reason : err.data.message ? err.data.message : err.message
            )
        }
    }, [poolInstance, fetchClaimInfo])

    useEffect(() => {
        fetchClaimInfo()
        const inter = setInterval(() => {
            fetchClaimInfo()
        }, 6500)

        return () => clearInterval(inter)
    }, [fetchClaimInfo])

    return useMemo(() => {
        return {
            claimRewards,
            claimPeriod,
            claim
        }
    }, [claimRewards, claimPeriod, claim])
}