import React from "react";
import PropTypes from "prop-types";

export const SwapButton = ({
    onClick,
    text="",
    disabled=false
}) => {
    return (
        <>
            <button className="token-button" disabled={disabled} onClick={onClick}>
                <span>
                    { text }
                </span>
                <svg id="Group_22318" data-name="Group 22318" xmlns="http://www.w3.org/2000/svg" width="359.843" height="87.528" viewBox="0 0 359.843 87.528">
            <rect id="Rectangle_11605" data-name="Rectangle 11605" width="300.488" height="43.679" transform="translate(29.173 21.885)" fill="#01ffff" opacity="0.15" />
            <path id="Path_18821" data-name="Path 18821" d="M1648.708,1296.062H1341.566V1247.5h307.142Zm-303.945-1.327h300.748v-45.913H1344.763Z" transform="translate(-1315.216 -1228.015)" fill="#01ffff" opacity="0.15" />
            <path id="Path_18822" data-name="Path 18822" d="M1323.747,1273.87v-37.124H1334.2v44.288Z" transform="translate(-1323.747 -1226.292)" fill="#01ffff" />
            <path id="Path_18823" data-name="Path 18823" d="M1388.172,1229.676h-64.425v10.455h71.589Z" transform="translate(-1323.747 -1229.676)" fill="#01ffff" />
            <path id="Path_18824" data-name="Path 18824" d="M1427.013,1233.211h-63.606v5.228h67.188Z" transform="translate(-1305.458 -1227.984)" fill="#01ffff" />
            <path id="Path_18825" data-name="Path 18825" d="M1418.253,1233.211h-6.489l3.582,5.228h6.489Z" transform="translate(-1344.998 -1227.984)" fill="#01ffff" />
            <path id="Path_18826" data-name="Path 18826" d="M1427.013,1233.211h-6.488l3.582,5.228h6.489Z" transform="translate(-1347.113 -1227.984)" fill="#01ffff" />
            <path id="Path_18827" data-name="Path 18827" d="M1435.774,1233.211h-6.489l3.582,5.228h6.489Z" transform="translate(-1349.229 -1227.984)" fill="#01ffff" />
            <path id="Path_18828" data-name="Path 18828" d="M1761.8,1229.676h37.124v10.455h-44.288Z" transform="translate(-1449.54 -1229.676)" fill="#01ffff" />
            <path id="Path_18829" data-name="Path 18829" d="M1795.046,1294.1v-64.425h-10.455v71.589Z" transform="translate(-1435.203 -1229.676)" fill="#01ffff" />
            <path id="Path_18830" data-name="Path 18830" d="M1789.818,1332.942v-63.606h-5.227v67.188Z" transform="translate(-1435.203 -1259.759)" fill="#01ffff" />
            <path id="Path_18831" data-name="Path 18831" d="M1795.046,1400.187v37.124h-10.455v-44.288Z" transform="translate(-1435.203 -1360.237)" fill="#01ffff" />
            <path id="Path_18832" data-name="Path 18832" d="M1750.412,1433.427h64.425v-10.455h-71.589Z" transform="translate(-1454.994 -1345.9)" fill="#01ffff" />
            <path id="Path_18833" data-name="Path 18833" d="M1710.146,1428.2h63.606v-5.228h-67.188Z" transform="translate(-1471.858 -1345.9)" fill="#01ffff" />
            <path id="Path_18834" data-name="Path 18834" d="M1367.941,1433.427h-37.124v-10.455H1375.1Z" transform="translate(-1320.362 -1345.9)" fill="#01ffff" />
            <path id="Path_18835" data-name="Path 18835" d="M1323.747,1388.794v64.425H1334.2V1381.63Z" transform="translate(-1323.747 -1365.691)" fill="#01ffff" />
            <path id="Path_18836" data-name="Path 18836" d="M1327.282,1348.528v63.606h5.227v-67.188Z" transform="translate(-1322.054 -1334.183)" fill="#01ffff" />
            <path id="Path_18837" data-name="Path 18837" d="M1671.511,1305.127H1329.917v-69.281h341.594Zm-340.245-.663h338.9V1236.51h-338.9Z" transform="translate(-1322.076 -1227.316)" fill="#006f76" />
            <path id="Path_18838" data-name="Path 18838" d="M1328.278,1319.735l-2.153-1.6V1261.1h2.153Z" transform="translate(-1322.608 -1246.798)" fill="#01ffff" />
            </svg>
            </button>
        </>
    )
}

SwapButton.propTypes = {
    onClick: PropTypes.func,
    text: PropTypes.string.isRequired,
    disabled: PropTypes.bool
}