import React from 'react';
import PoolCom from '../../Components/PoolCom/PoolCom';
const Pool = () => {
    return (
        <>
            <PoolCom />
        </>
    )
}

export default Pool