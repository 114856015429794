import { NativeCurrency } from "@uniswap/sdk-core";

export class Token {
    name;
    decimals;
    symbol;
    address;
    isNative;
    chainId;
    isToken;

    constructor(_address, _name, _symbol, _decimals, _chainId, _isNative = false, _isToken = true) {
        this.name = _name;
        this.decimals = _decimals;
        this.symbol = _symbol;
        this.address = _address;
        this.isNative = _isNative;
        this.chainId = _chainId;
        this.isToken = _isToken;
    }

    toJSON() {
        return {
            name: this.name,
            decimals: this.decimals,
            symbol: this.symbol,
            address: this.address,
            isNative: this.isNative,
            chainId: this.chainId,
            isToken: this.isToken
        }
    }

    static fromJSON(json) {
        return new Token(
            json.address,
            json.name,
            json.symbol,
            json.decimals,
            json.chainId,
            json.isNative,
            json.isToken
        )
    }

    sortsBefore(tokenB) {
        return this.address?.toLowerCase() < tokenB?.address.toLowerCase()
    }

    equals(other) {
        return this.address?.toLowerCase() === other.address?.toLowerCase()
    }

    get wrapped() {
        return this
    }
}

export const WRAPPED_MATIC = new Token(
    '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    'Wrapped MATIC',
    'WMATIC',
    18,
    137
)

export class MaticNativeCurrency extends NativeCurrency {
    equals(other) {
        return other.isNative && other.chainId === this.chainId
    }

    get wrapped() {
        return WRAPPED_MATIC
    }

    constructor(chainId) {
        super(chainId, 18, 'MATIC', 'Polygon Matic')
    }
}

export const MATIC = new Token(
    "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "MATIC",
    "MATIC",
    18,
    137,
    true,
    false
)

export const wMATIC = new Token(
    "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270",
    "Wrapped MATIC",
    "wMATIC",
    18,
    137
)

export const OCToken = new Token(
    "0x6eA4BaBF46AfC7895ee20594b86fDcF74526c3ec", // 0x6032e93dDC9Ef65212A9bDe047A93e5d16126C8e // 0x8047a4ace975ae863536cbde3338ebda6f5f1c0a
    "OxChange",
    "OC",
    18,
    137
)

export const USDT = new Token(
    "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
    "USDT",
    "USDT",
    6,
    137
)

export const USDC = new Token(
    "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
    "USDC",
    "USDC",
    6,
    137
)

export const wETH = new Token(
    "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619",
    "Wrapped Ethereum",
    "wETH",
    18,
    137
)

export const wBTC = new Token(
    "0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6",
    "Wrapped Bitcoin",
    "wBTC",
    8,
    137
)

export const WRAPPED_NATIVE_CURRENCY = (chainId) => {
    switch (chainId) {
        case 137:
            return WRAPPED_MATIC
        default:
            return WRAPPED_MATIC
    }
}

export const defaultTokens = [
    MATIC,
    wMATIC,
    OCToken,
    USDT,
    USDC,
    wETH,
    wBTC
]

export const PINNED_TOKENS = [OCToken, USDT]