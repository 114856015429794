import { useEffect, useMemo, useState } from "react"
import { useBestTrade } from "./useBestTrade"
import { JSBI } from "@uniswap/sdk"
import { OCToken, wMATIC } from "../config/tokens"
import { CurrencyAmount, TradeType } from "@uniswap/sdk-core"
import { useCurrency } from "./Tokens"
import tryParseCurrencyAmount from "../utils/tryParseCurrencyAmount"
import { useMaticPrice, useOCFetchPrice } from "./usePrice"
// import useActiveWeb3React from "./useWeb3"

export const useOcPrice = () => {
    // const { library, chainId } = useActiveWeb3React()
    const [ price, setPrice ] = useState()
    const maticPrice = useMaticPrice()
    const ocPrice = useOCFetchPrice()

    
    useEffect(() => {
        if (ocPrice) {
            const ocPriceInMatic = ocPrice
            setPrice(ocPriceInMatic)
        }
    }, [ocPrice])

    return useMemo(() => price, [price])
}