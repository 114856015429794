import {useEffect, useMemo, useState} from "react";
import {Token, wMATIC} from "../config/tokens";

export const useWrappedToken = (token: Token) => {
    const [ wToken, setWToken ] = useState(token)

    useEffect(() => {
        if ( token?.isNative ) {
            setWToken(wMATIC)
        } else {
            setWToken(token)
        }
    }, [setWToken, token])

    return useMemo(() => wToken, [wToken])
}