
import useActiveWeb3React from '../hooks/useWeb3'
import { useSwapCallback as useLibSwapCallBack } from '../state/swap/useSwapCallback'
import { useMemo } from 'react'

import useTransactionDeadline from './useTransactionDeadline'

export function useSwapCallback(
    trade,
    allowedSlippage,
    recipientAddressOrName,
    signatureData
) {
    const { account } = useActiveWeb3React()

    const deadline = useTransactionDeadline()

    const { address: recipientAddress } = {address: null}
    const recipient = recipientAddressOrName === null ? account : recipientAddress

    const {
        state,
        callback: libCallback,
        error,
    } = useLibSwapCallBack({ trade, allowedSlippage, recipientAddressOrName: recipient, signatureData, deadline })

    const callback = useMemo(() => {
        if (!libCallback || !trade) {
            return null
        }
        return () =>
            libCallback()
    }, [ libCallback, trade])

    return {
        state,
        callback,
        error,
    }
}
