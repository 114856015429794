import {useDispatch, useSelector} from "../../redux/store";
import {useCallback, useMemo} from "react";
import {Percent} from "@uniswap/sdk-core";
import JSBI from "jsbi";
import {updateUserClientSideRouter, updateUserSlippageTolerance} from "./slice";

export function useUserSlippageTolerance() {
    const userSlippageToleranceRaw = useSelector((state) => {
        return state.user.userSlippageTolerance
    })
    const userSlippageTolerance = useMemo(
        () => (userSlippageToleranceRaw === 'auto' ? 'auto' : new Percent(userSlippageToleranceRaw, 10_000)),
        [userSlippageToleranceRaw]
    )

    const dispatch = useDispatch()
    const setUserSlippageTolerance = useCallback(
        (userSlippageTolerance) => {
            let value: 'auto' | number
            try {
                value =
                    userSlippageTolerance === 'auto' ? 'auto' : JSBI.toNumber(userSlippageTolerance.multiply(10_000).quotient)
            } catch (error) {
                value = 'auto'
            }
            dispatch(
                updateUserSlippageTolerance({
                    userSlippageTolerance: value,
                })
            )
        },
        [dispatch]
    )

    return useMemo(
        () => [userSlippageTolerance, setUserSlippageTolerance],
        [setUserSlippageTolerance, userSlippageTolerance]
    )
}

export function useClientSideRouter(): [boolean, (userClientSideRouter: boolean) => void] {
    const dispatch = useDispatch()

    const clientSideRouter = useSelector((state) => Boolean(state.user.userClientSideRouter))

    const setClientSideRouter = useCallback(
        (newClientSideRouter: boolean) => {
            dispatch(updateUserClientSideRouter({ userClientSideRouter: newClientSideRouter }))
        },
        [dispatch]
    )

    return [clientSideRouter, setClientSideRouter]
}

export function useUserSlippageToleranceWithDefault(defaultSlippageTolerance) {
    const allowedSlippage = useUserSlippageTolerance()[0]
    return useMemo(
        () => (allowedSlippage === 'auto' ? defaultSlippageTolerance : allowedSlippage),
        [allowedSlippage, defaultSlippageTolerance]
    )
}