import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import logo from '../../Assets/images/oc-change-logo.png'
import Hamburgericon from '../../Assets/images/hamburger.svg'
import './header.css'
import { ConnectWalletModal } from "../ConnectWallet/ConnectWalletModal";

const Header = () => {
    const [showNavbar, setShowNavbar] = useState(false)

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <>
            <header>
                <nav className="navbar">
                    <div className="navbar_inner oc_row space-between align-items-center">
                        <div className="logo">
                            <NavLink to="/" onClick={scrollToTop}><img src={logo} alt="logo" width="140" height="140" /></NavLink>
                        </div>
                        <div className={`menu-icon ${showNavbar && 'active'}`} onClick={handleShowNavbar}>
                            <img src={Hamburgericon} alt="Hamburger" width="30" height="25" />
                        </div>
                        <div className={`nav-elements ${showNavbar && 'active'}`}>
                            <ul>
                                <li>
                                    <NavLink to="/swap" activeClassName="active" onClick={handleShowNavbar}>Swap</NavLink>
                                </li>
                                <li>
                                    <NavLink to="/staking" activeClassName="active" onClick={handleShowNavbar}>Staking</NavLink>
                                </li>
                                {/* <li>
                                    <NavLink to="/lottery" activeClassName="active" onClick={handleShowNavbar}>Lottery</NavLink>
                                </li> */}
                            </ul>
                        </div>
                        <div className="balancebox">
                            <ul>
                                <li>
                                    <div className='connectBtnMain'>
                                        <ConnectWalletModal />
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}

export default Header