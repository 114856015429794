import React from 'react';
import { useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { StakingPool } from "../../Pages/Staking/StakingPool";
import { Pools } from "../../config/pools";
import { useOcPrice } from '../../hooks/useOCPrice';
import { setupNetwork } from '../../utils/setupNetwork';

const Gridlistview = () => {
    const [isActive, setIsActive] = useState(false);

    const ocPrice = useOcPrice()

    return (
        <>
            <section className={`gridlistview ${isActive ? 'listviewmode' : ""} `} id="farming">
                <div className="farming-container ox_container">
                    <Tabs>
                        <div className="farming-button-area">
                            <div className="flex-btn-group">
                                <div className="view-btn-group">
                                    <div className="list-btn">
                                        <button className={`gridbtn ${!isActive ? "active" : ""}`} onClick={() => setIsActive(false)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="46.42" height="46.367" viewBox="0 0 46.42 46.367">
                                                <g id="Group_22488" data-name="Group 22488" transform="translate(-665.5 -197.489)">
                                                    <g id="Group_22401" data-name="Group 22401" transform="translate(710.756 194.875) rotate(90)">
                                                        <path id="Path_18783" data-name="Path 18783" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.147,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 24.61, 14.858)" fill="#01ffff" />
                                                        <path id="Path_18784" data-name="Path 18784" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.148,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 51.595, 14.858)" fill="#01ffff" />
                                                        <path id="Path_27644" data-name="Path 27644" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.148,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 39.425, 14.806)" fill="#01ffff" />
                                                    </g>
                                                    <g id="Group_22402" data-name="Group 22402" transform="translate(698.281 194.875) rotate(90)">
                                                        <path id="Path_18783-2" data-name="Path 18783" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.147,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 24.61, 14.858)" fill="#01ffff" />
                                                        <path id="Path_18784-2" data-name="Path 18784" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.148,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 51.595, 14.858)" fill="#01ffff" />
                                                        <path id="Path_27644-2" data-name="Path 27644" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.148,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 39.425, 14.806)" fill="#01ffff" />
                                                    </g>
                                                    <g id="Group_22403" data-name="Group 22403" transform="translate(685.809 194.875) rotate(90)">
                                                        <path id="Path_18783-3" data-name="Path 18783" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.147,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 24.61, 14.858)" fill="#01ffff" />
                                                        <path id="Path_18784-3" data-name="Path 18784" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.148,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 51.595, 14.858)" fill="#01ffff" />
                                                        <path id="Path_27644-3" data-name="Path 27644" d="M4.039,21.944l6.73-10.173L8.878,1.5,2.148,11.669Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 39.425, 14.806)" fill="#01ffff" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="gird-btn" style={{ display: 'none' }}>
                                        <button className={`listbtn ${!isActive ? "active" : ""}`} onClick={() => setIsActive(true)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="39.585" height="51.595" viewBox="0 0 39.585 51.595">
                                                <g id="Group_22404" data-name="Group 22404" transform="translate(-727.852 -194.875)">
                                                    <g id="Group_22575" data-name="Group 22575">
                                                        <g id="Group_22399" data-name="Group 22399" transform="translate(767.438 194.875) rotate(90)">
                                                            <path id="Path_18783" data-name="Path 18783" d="M0,27.114,10.047,12.7V0L0,14.418Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 24.611, 21.441)" fill="#03a7a7" />
                                                            <path id="Path_18784" data-name="Path 18784" d="M0,27.113,10.047,12.7V0L0,14.418Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 51.596, 21.441)" fill="#03a7a7" />
                                                            <path id="Path_27644" data-name="Path 27644" d="M0,27.113,10.047,12.7V0L0,14.418Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 39.426, 21.366)" fill="#03a7a7" />
                                                        </g>
                                                        <g id="Group_22400" data-name="Group 22400" transform="translate(740.75 201.155) rotate(90)">
                                                            <path id="Path_18783-2" data-name="Path 18783" d="M4.544,11.1,6.621,6.88,2.078,0,0,4.218Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 12.051, 8.821)" fill="#03a7a7" />
                                                            <path id="Path_18784-2" data-name="Path 18784" d="M4.544,11.1,6.621,6.88,2.078,0,0,4.218Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 39.035, 8.821)" fill="#03a7a7" />
                                                            <path id="Path_27644-2" data-name="Path 27644" d="M4.544,11.1,6.622,6.88,2.078,0,0,4.218Z" transform="matrix(-0.788, 0.616, -0.616, -0.788, 26.865, 8.745)" fill="#03a7a7" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="farming-outer-button-border">
                                    <div className="farming-inner-button-border">
                                        <div className="farming-button-grp">
                                            <TabList>
                                                <Tab>Live</Tab>
                                                <Tab>Finished</Tab>
                                            </TabList>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="search-box-content">
                                <div className="search-bar-box">
                                    <div className="search-bar">
                                        <div className="frame-sub-box-3">
                                            <button className='token-button' onClick={() => {
                                                setupNetwork({ library: null })
                                            }}>
                                                <span style={{ fontSize: '14px' }}>
                                                    Add Mainnet
                                                </span>
                                                <div className="bg-button">
                                                    <svg id="Group_22317" data-name="Group 22317" xmlns="http://www.w3.org/2000/svg" width="657.251" height="134.36" viewBox="0 0 657.251 134.36">
                                                        <g id="Group_13117" data-name="Group 13117">
                                                            <path id="Path_18918" data-name="Path 18918" d="M1773.355,887.338h-41.4l-2.169,1.184,2.169,1.184h41.4v19.139l-1.1,3.015,6.226-3.015V887.338Z" transform="translate(-1138.279 -883.309)" fill="#01ffff" />
                                                            <path id="Path_18919" data-name="Path 18919" d="M1330.867,912.641V898.564l2.166.647v32.445l6.7,2.122V885.516h73.645l-4.589-3.1h-60.1l-1.4-1h20.373l-3.105-2.1h-38.219V911.2Zm2.166-30.823c1.514,0,2.741.567,2.741,1.267s-1.227,1.267-2.741,1.267-2.74-.567-2.74-1.267S1331.519,881.818,1333.032,881.818Zm0,5.422c1.514,0,2.741.568,2.741,1.267s-1.227,1.267-2.741,1.267-2.74-.567-2.74-1.267S1331.519,887.24,1333.032,887.24Zm0,5.422c1.514,0,2.741.568,2.741,1.268s-1.227,1.267-2.741,1.267-2.74-.567-2.74-1.267S1331.519,892.663,1333.032,892.663Z" transform="translate(-1323.521 -879.322)" fill="#01ffff" />
                                                            <path id="Path_18920" data-name="Path 18920" d="M1448.876,1010.273H1337.7V891.652h111.155l32.23,8.544h333.629l32.232-8.544h111.155v118.621H1846.933l-32.232-8.849H1481.107Zm-106.95-2h105.813l32.231-8.85h335.869l32.232,8.85h105.813V893.648H1848.053l-32.232,8.544H1479.987l-32.232-8.544H1341.926Z" transform="translate(-1322.532 -885.455)" fill="#006f76" />
                                                            <rect id="Rectangle_11642" data-name="Rectangle 11642" width="212.958" height="3.189" transform="translate(217.233 115.664)" fill="#01ffff" />
                                                            <rect id="Rectangle_11643" data-name="Rectangle 11643" width="1.733" height="23.211" transform="translate(637.483 50.589)" fill="#006f76" />
                                                            <path id="Path_18921" data-name="Path 18921" d="M1332.714,1048.7v-22.9l-8.967,2.639v26.453h33.148l3.106-2.1h-20.373l1.4-1h164.6l4.59-3.1Z" transform="translate(-1323.747 -921.489)" fill="#01ffff" />
                                                            <path id="Path_18922" data-name="Path 18922" d="M1792.7,998.376v14.077l-2.167-.647V979.361l-6.7-2.122V1025.5H1710.2l4.589,3.1h60.1l1.4,1h-20.373l3.106,2.1h38.218V999.813Zm-2.167,30.823c-1.513,0-2.74-.568-2.74-1.268s1.227-1.267,2.74-1.267,2.741.568,2.741,1.267S1792.052,1029.2,1790.537,1029.2Zm0-5.423c-1.513,0-2.74-.567-2.74-1.267s1.227-1.267,2.74-1.267,2.741.568,2.741,1.267S1792.052,1023.777,1790.537,1023.777Zm0-5.422c-1.513,0-2.74-.567-2.74-1.267s1.227-1.268,2.74-1.268,2.741.568,2.741,1.268S1792.052,1018.354,1790.537,1018.354Z" transform="translate(-1139.984 -897.336)" fill="#01ffff" />
                                                            <rect id="Rectangle_11644" data-name="Rectangle 11644" width="366.148" height="1.214" transform="translate(231.624 123.471)" fill="#006f76" />
                                                            <rect id="Rectangle_11645" data-name="Rectangle 11645" width="5.527" height="2.555" transform="translate(619.058 10.191)" fill="#01ffff" />
                                                            <rect id="Rectangle_11646" data-name="Rectangle 11646" width="5.527" height="2.555" transform="translate(608.882 10.191)" fill="#01ffff" />
                                                            <rect id="Rectangle_11647" data-name="Rectangle 11647" width="5.527" height="2.555" transform="translate(598.707 10.191)" fill="#01ffff" />
                                                            <rect id="Rectangle_11648" data-name="Rectangle 11648" width="5.527" height="2.555" transform="translate(588.531 10.191)" fill="#01ffff" />
                                                            <rect id="Rectangle_11649" data-name="Rectangle 11649" width="5.527" height="2.555" transform="translate(578.355 10.191)" fill="#01ffff" />
                                                            <rect id="Rectangle_11650" data-name="Rectangle 11650" width="5.527" height="2.555" transform="translate(68.926 117.576)" fill="#01ffff" />
                                                            <rect id="Rectangle_11651" data-name="Rectangle 11651" width="5.527" height="2.555" transform="translate(58.75 117.576)" fill="#01ffff" />
                                                            <rect id="Rectangle_11652" data-name="Rectangle 11652" width="5.527" height="2.555" transform="translate(48.574 117.576)" fill="#01ffff" />
                                                            <rect id="Rectangle_11653" data-name="Rectangle 11653" width="5.527" height="2.555" transform="translate(38.398 117.576)" fill="#01ffff" />
                                                            <rect id="Rectangle_11654" data-name="Rectangle 11654" width="5.527" height="2.555" transform="translate(28.223 117.576)" fill="#01ffff" />
                                                            <path id="Path_18923" data-name="Path 18923" d="M1752.72,1052.09h-88.686v-1.479h85.517Z" transform="translate(-1144.001 -933.832)" fill="#006f76" />
                                                            <path id="Path_18924" data-name="Path 18924" d="M1475.16,905.732H1367.478l-3.322-1.48h111Z" transform="translate(-1320.231 -891.723)" fill="#006f76" />
                                                            <path id="Path_18925" data-name="Path 18925" d="M1403.416,887.278l1.8,1.215h359.236v-1.215Z" transform="translate(-1166.679 -883.28)" fill="#01ffff" />
                                                            <rect id="Rectangle_11655" data-name="Rectangle 11655" width="212.958" height="3.189" transform="translate(217.233 11.675)" fill="#01ffff" />
                                                        </g>
                                                        <rect id="Rectangle_11656" data-name="Rectangle 11656" width="601.057" height="65.464" transform="translate(24.157 31.899)" fill="#01ffff" opacity="0.15" />
                                                    </svg>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="scroll-border">
                            <span className='scroll-shadow'></span>
                            <div className="scroll">
                                <TabPanel>
                                    <div className="farming-content-area">
                                        {
                                            Pools.map((pool) => {
                                                return <StakingPool address={pool.address} apr={pool.apr} label={pool.label} lockPeriod={pool.lockPeriod} ocPrice={ocPrice} ogAPR={pool.ogAPR} />
                                            })
                                        }
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="farming-content-area">
                                        <h2>No Pools Found!</h2>
                                    </div>
                                </TabPanel>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </section>
        </>
    )
}

export default Gridlistview