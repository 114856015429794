import React from 'react';
import Gridlistview from '../../Components/StakingCom/Gridlistview';
import { Toaster } from 'react-hot-toast';
const Staking = () => {
    return (
        <>
            <Gridlistview />
            <Toaster />
            {/* <h2>Staking will be live soon!</h2> */}
        </>
    )
}

export default Staking