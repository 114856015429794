import { createReducer } from '@reduxjs/toolkit'
import { parsedQueryString } from '../../hooks/useParsedQueryString'

import { Field, replaceSwapState, selectCurrency, setRecipient, switchCurrencies, typeInput } from './actions'
import { queryParametersToSwapState } from './hooks'

const initialState = queryParametersToSwapState(parsedQueryString())

export default createReducer(initialState, (builder) =>
    builder
        .addCase(
            replaceSwapState,
            (state, { payload: { typedValue, recipient, field, inputCurrencyId, outputCurrencyId } }) => {
                return {
                    [Field.INPUT]: {
                        currencyId: inputCurrencyId ?? null,
                    },
                    [Field.OUTPUT]: {
                        currencyId: outputCurrencyId ?? null,
                    },
                    independentField: field,
                    typedValue,
                    recipient,
                }
            }
        )
        .addCase(selectCurrency, (state, { payload: { currencyId, field } }) => {
            const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
            if (currencyId === state[otherField].currencyId) {
                // the case where we have to swap the order
                return {
                    ...state,
                    independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
                    [field]: { currencyId },
                    [otherField]: { currencyId: state[field].currencyId },
                }
            } else {
                // the normal case
                return {
                    ...state,
                    [field]: { currencyId },
                }
            }
        })
        .addCase(switchCurrencies, (state) => {
            return {
                ...state,
                [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
                [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
            }
        })
        .addCase(typeInput, (state, { payload: { field, typedValue } }) => {
            return {
                ...state,
                independentField: field,
                typedValue,
            }
        })
        .addCase(setRecipient, (state, { payload: { recipient } }) => {
            state.recipient = recipient
        })
)
