import { useApproval } from './useApproval'
import { useCallback } from 'react'
import {showToast} from "../utils";
import useSwapApproval, {useSwapApprovalOptimizedTrade} from "./useSwapApproval";

function useGetAndTrackApproval(getApproval) {
    return useCallback(async () => {
        const response = await getApproval()
        await showToast({
            type: "promise",
            promise: response.wait(),
            loading: "Approving",
            success: "Approved",
        })
    }, [getApproval])
}

export function useApprovalOptimizedTrade(
    trade,
    allowedSlippage
) {
    return useSwapApprovalOptimizedTrade(trade, allowedSlippage)
}

export function useApproveCallback(
    amountToApprove,
    spender
) {
    const [approval, getApproval] = useApproval(amountToApprove, spender)
    return [approval, useGetAndTrackApproval(getApproval)]
}

export function useApproveCallbackFromTrade(
    trade,
    allowedSlippage
) {
    const [approval, getApproval] = useSwapApproval(trade, allowedSlippage)
    return [approval, useGetAndTrackApproval(getApproval)]
}