import { BigNumberish } from '@ethersproject/bignumber'
import { CurrencyAmount, MaxUint256 } from '@uniswap/sdk-core'
import { useTokenContract } from './useContract'
import { useSingleCallResult } from './multicall'
import { useCallback, useEffect, useMemo, useState } from 'react'

export function useTokenAllowance(
    token,
    owner,
    spender
) {
    const contract = useTokenContract(token?.address)
    const inputs = useMemo(() => [owner, spender], [owner, spender])

    const [blocksPerFetch, setBlocksPerFetch] = useState()
    const { result, syncing: isSyncing } = useSingleCallResult(contract, 'allowance', inputs, { blocksPerFetch })

    const rawAmount = result?.toString()
    const allowance = useMemo(
        () => (token && rawAmount ? CurrencyAmount.fromRawAmount(token, rawAmount) : undefined),
        [token, rawAmount]
    )
    useEffect(() => setBlocksPerFetch(allowance?.equalTo(0) ? 1 : undefined), [allowance])

    return useMemo(() => ({ tokenAllowance: allowance, isSyncing }), [allowance, isSyncing])
}

export function useUpdateTokenAllowance(
    amount,
    spender
) {
    const contract = useTokenContract(amount?.currency.address)

    return useCallback(async () => {
        try {
            if (!amount) throw new Error('missing amount')
            if (!contract) throw new Error('missing contract')
            if (!spender) throw new Error('missing spender')

            const allowance = MaxUint256.toString()
            const response = await contract.approve(spender, allowance)
            return {
                response,
                info: {
                    type: 'Approval',
                    tokenAddress: contract.address,
                    spender,
                },
            }
        } catch (e) {
            const symbol = amount?.currency.symbol ?? 'Token'
            throw new Error(`${symbol} token allowance failed: ${e instanceof Error ? e.message : e}`)
        }
    }, [amount, contract, spender])
}
