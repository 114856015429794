import { useEffect, useMemo, useState } from "react"
import { getMaticPrice, getOCPrice } from "../utils/getMaticPrice"

export const useMaticPrice = () => {
    const [price, setPrice] = useState()

    useEffect(() => {
        const getPrice = async () => {
            const price = await getMaticPrice()
            setPrice(price)
        }
        getPrice()
    }, [])

    return useMemo(() => price, [price])
}

export const useOCFetchPrice = () => {
    const [price, setPrice] = useState()

    useEffect(() => {
        const getPrice = async () => {
            const price = await getOCPrice()
            setPrice(price)
        }
        getPrice()
    }, [])

    return useMemo(() => price, [price])
}