export const interval = 24 * 60 * 60
export const unstakeBefore = 10
export const Pools = [
    {
        label: "7 Days",
        apr: 4,
        lockPeriod: 7 * interval,
        ogAPR: 50,
        address: "0xc48a5fC5b45723e2472efb4bc4A2caE21c56f390"
    },
    {
        label: "30 Days",
        apr: 6,
        lockPeriod: 30 * interval,
        ogAPR: 100,
        address: "0x907D7655Fba8c389fb24047b0b3c4596Dc1b53c9"
    },
    {
        label: "90 Days",
        apr: 8,
        lockPeriod: 90 * interval,
        ogAPR: 150,
        address: "0xB6437A38352fa14ea3993297ee69570B5f4d8a2c"
    },
    {
        label: "180 Days",
        apr: 10,
        lockPeriod: 180 * interval,
        ogAPR: 200,
        address: "0x81Cd96Cc27D19E4444D4069f58C4BC5057Aa5963"
    },
    {
        label: "360 Days",
        apr: 12,
        lockPeriod: 360 * interval,
        ogAPR: 300,
        address: "0x8C5Ac90eE1F23A49d52eCbe565040Dd52A38CB85"
    }
]