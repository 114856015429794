import { Protocol } from '@uniswap/router-sdk'
import { Pair, Route as V2Route, Trade as V2Trade } from '@uniswap/v2-sdk'
import { Pool, Route as V3Route, Trade as V3Trade } from '@uniswap/v3-sdk'
import {CONTRACTS} from '../config/contracts'
import useActiveWeb3React from './useWeb3'
import { useERC20PermitFromTrade, UseERC20PermitState } from './useERC20Permit'
import useTransactionDeadline from './useTransactionDeadline'
import { useCallback, useMemo } from 'react'
import invariant from 'tiny-invariant'
import { getTxOptimizedSwapRouter, SwapRouterVersion } from '../utils/getTxOptimizedSwapRouter'

import { ApprovalState, useApproval, useApprovalStateForSpender } from './useApproval'

export { ApprovalState } from './useApproval'

function useSwapApprovalStates(
    trade,
    allowedSlippage
) {
    const { chainId } = useActiveWeb3React()

    const amountToApprove = useMemo(
        () => (trade && trade.inputAmount.currency.isToken ? trade.maximumAmountIn(allowedSlippage) : undefined),
        [trade, allowedSlippage]
    )

    const v2RouterAddress =  undefined
    const v3RouterAddress = chainId ? CONTRACTS.SWAP_ROUTER[chainId] : undefined
    const swapRouterAddress = chainId ? CONTRACTS.SWAP_ROUTER_02[chainId] : undefined
    const v2 = useApprovalStateForSpender(amountToApprove, v2RouterAddress)
    const v3 = useApprovalStateForSpender(amountToApprove, v3RouterAddress)
    const v2V3 = useApprovalStateForSpender(amountToApprove, swapRouterAddress)

    return useMemo(() => ({ v2, v3, v2V3 }), [v2, v2V3, v3])
}

export function useSwapRouterAddress(
    trade
) {
    const { chainId } = useActiveWeb3React()
    return useMemo(
        () =>
            chainId
                ? CONTRACTS.SWAP_ROUTER[chainId]
                : CONTRACTS.SWAP_ROUTER_02[chainId],
        [chainId]
    )
}

// wraps useApproveCallback in the context of a swap
export default function useSwapApproval(
    trade,
    allowedSlippage,
    amount?
) {
    const amountToApprove = useMemo(
        () => amount || (trade && trade.inputAmount.currency.isToken ? trade.maximumAmountIn(allowedSlippage) : undefined),
        [amount, trade, allowedSlippage]
    )
    const spender = useSwapRouterAddress(trade)

    const approval = useApproval(amountToApprove, spender)
    if (trade instanceof V2Trade || trade instanceof V3Trade) {
        const approvalState = approval[0]
        invariant(approvalState === ApprovalState.APPROVED, 'Trying to approve legacy router')
    }
    return approval
}

export function useSwapApprovalOptimizedTrade(
    trade,
    allowedSlippage
) {
    const onlyV2Routes = trade?.routes.every((route) => route.protocol === Protocol.V2)
    const onlyV3Routes = trade?.routes.every((route) => route.protocol === Protocol.V3)
    const tradeHasSplits = (trade?.routes.length ?? 0) > 1

    const approvalStates = useSwapApprovalStates(trade, allowedSlippage)

    const optimizedSwapRouter = useMemo(
        () => getTxOptimizedSwapRouter({ onlyV2Routes, onlyV3Routes, tradeHasSplits, approvalStates }),
        [approvalStates, tradeHasSplits, onlyV2Routes, onlyV3Routes]
    )

    return useMemo(() => {
        if (!trade) return undefined
        // trade.swaps[0].outputAmount = CurrencyAmount.fromRawAmount(trade.swaps[0].route.output, 1)

        try {
            switch (optimizedSwapRouter) {
                case SwapRouterVersion.V2V3:
                    return trade
                case SwapRouterVersion.V2:
                    const pairs = trade.swaps[0].route.pools.filter((pool) => pool instanceof Pair)
                    const v2Route = new V2Route(pairs, trade.inputAmount.currency, trade.outputAmount.currency)
                    return new V2Trade(v2Route, trade.inputAmount, trade.tradeType)
                case SwapRouterVersion.V3:
                    return V3Trade.createUncheckedTradeWithMultipleRoutes({
                        routes: trade.swaps.map(({ route, inputAmount, outputAmount }) => ({
                                    route: new V3Route(
                                        route.pools.filter((p) => p instanceof Pool),
                                    inputAmount.currency,
                                    outputAmount.currency
                            ),
                            inputAmount,
                            outputAmount
                    })),
                    tradeType: trade.tradeType,
            })
        default:
            return undefined
        }
        } catch (e) {
            // TODO(#2989): remove try-catch
            console.debug(e)
            return undefined
        }
    }, [trade, optimizedSwapRouter])
}

export const ApproveOrPermitState = {
    REQUIRES_APPROVAL:0,
    PENDING_APPROVAL:1,
    REQUIRES_SIGNATURE:2,
    PENDING_SIGNATURE:3,
    APPROVED:4,
}

export const useApproveOrPermit = (
    trade,
    allowedSlippage,
    amount?
) => {
    const deadline = useTransactionDeadline()

    // Check approvals on ERC20 contract based on amount.
    const [approval, getApproval] = useSwapApproval(trade, allowedSlippage, amount)

    // Check status of permit and whether token supports it.
    const {
        state: signatureState,
        signatureData,
        gatherPermitSignature,
    } = useERC20PermitFromTrade(trade, allowedSlippage, deadline)

    const notApproved = approval === ApprovalState.NOT_APPROVED && !(signatureState === UseERC20PermitState.SIGNED)

    // If permit is supported, trigger a signature, if not create approval transaction.
    const handleApproveOrPermit = useCallback(async () => {
        if (signatureState === UseERC20PermitState.NOT_SIGNED && gatherPermitSignature) {
            try {
                return await gatherPermitSignature()
            } catch (error) {
                // Try to approve if gatherPermitSignature failed for any reason other than the user rejecting it.
                if (error?.code !== 4001) {
                    return getApproval()
                }
            }
        } else {
            return getApproval()
        }
    }, [signatureState, gatherPermitSignature, getApproval])

    const approvalState = useMemo(() => {
        if (approval === ApprovalState.PENDING) {
            return ApproveOrPermitState.PENDING_APPROVAL
        }
        if (signatureState === UseERC20PermitState.LOADING) {
            return ApproveOrPermitState.PENDING_SIGNATURE
        }
        if (notApproved && Boolean(gatherPermitSignature)) {
            return ApproveOrPermitState.REQUIRES_SIGNATURE
        }
        if (notApproved) {
            return ApproveOrPermitState.REQUIRES_APPROVAL
        }
        return ApproveOrPermitState.APPROVED
    }, [approval, gatherPermitSignature, notApproved, signatureState])

    return {
        approvalState,
        signatureData,
        handleApproveOrPermit,
    }
}
