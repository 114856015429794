
const mainnetContracts = {
    "v3CoreFactoryAddress": "0xAeFA1e80C86391CaD041d1B479d2B23261DE54e7",
    "multicall2Address": "0x78FC4D0fe7104c342b868D5EfabDA3b12189a301",
    "proxyAdminAddress": "0xDA9007E8208b4EdaC6Bc103bc645F1a02fD0b739",
    "tickLensAddress": "0xda662B30A5B3369acbc6c2Beb10F734eD0A74F3e",
    "nftDescriptorLibraryAddressV1_3_0": "0x6CfB630C10C5Bef9fdB709f6E4b4aBe481b74c30",
    "nonfungibleTokenPositionDescriptorAddressV1_3_0": "0xeF8d2FDadf558D70773C138BF6d7ECCCe48C2D0e",
    "descriptorProxyAddress": "0x25284e289B7e06878465B6FE1932d98953fbe78C",
    "nonfungibleTokenPositionManagerAddress": "0x276dbf617A85E95a9317de9bF1F23Dd22c65c3B5",
    "v3MigratorAddress": "0xa89Cc98B2533F4976266D6c6Eb6b1cd27d4B0aE1",
    "v3StakerAddress": "0x460EC54De5e97E1774cf41DB256851DbE4E70F8E",
    "quoterV2Address": "0x57fAfBDB3AF73e79E23cd3eE1F9F92621F4871C2",
    "quoterAddress": "0x35e62ab0DaaD1b3d5a30adB501633214b1344972",
    "swapRouter02": "0x9B4d3882c1503399a731251ace5f2937c849A478", // 0x2Fb5C14Fdc81BbcEeEaaa0434dEcC2875A3efEb4
    "swapRouter": "0x9B4d3882c1503399a731251ace5f2937c849A478"
    // "v3CoreFactoryAddress": "0x57ED260d5288E6b16FF4713d06F6563cd843e35F",
    // "multicall2Address": "0x0738189E5900Bb78d221d6b2013896867381065E",
    // "proxyAdminAddress": "0xB0Cd2cd593DA6D53A759Bc489A3dE52A9243Be9B",
    // "tickLensAddress": "0x91d7EC19F63A490307178a7162FA7a5039D1FFA8", // TICK Lens
    // "nftDescriptorLibraryAddressV1_3_0": "0xd974683A7E1D877febF8BdcC392F6d689Efd0944",
    // "nonfungibleTokenPositionDescriptorAddressV1_3_0": "0xe953b99F28b7ddA3197A548035636dfF10601a33",
    // "descriptorProxyAddress": "0x9938e0bf3A641bD9a5b277049F0Caf92184CFCb1",
    // "nonfungibleTokenPositionManagerAddress": "0x5F436d6b9063d720cdf78891634eCE56dBDEE094", // NFT Manager
    // "v3MigratorAddress": "0x3fD7acb4Dc2F2FC2af5656D854fe3992c725DCDf", // MIGRATOR ADDRESS
    // "v3StakerAddress": "0xcfc6D39Cb46eC68AA74Dd3E539292cea3c2eD42B",
    // "quoterV2Address": "0xEdDbE6952B076d066c4efb18efc4af89F9fE43F3",
    // "quoterAddress": "0x951E831a72a9947e6E6252B2D251D3bacDF1D5Df", // Quoter Address
    // "swapRouter02": "0xce181A5292A676b9E0a7460dA784408d3C53776F", // SWAP_ROUTER_ADDRESS
    // "swapRouter": "0x7adE8B6FE24f8C334fcD5cBEf0c7347Da331e470" // V3_ROUTER_ADDRESS
}

const testnetContracts = {
    "v3CoreFactoryAddress": "0x17b1C48b5EC2778ebE8a146FAe6574b90d787c79",
    "multicall2Address": "0x4E79E87D4c982164D3375d63E3dd0E7a5b709B32",
    "proxyAdminAddress": "0x204E1f9bf2C012eB626849a1317D6F48108945Ee",
    "tickLensAddress": "0xf1D70573D97Ee8cf87fFff77f7aB7F0afF05B69f",
    "nftDescriptorLibraryAddressV1_3_0": "0x194b57113fe7725173D48c6b3B185BD0B74b1C22",
    "nonfungibleTokenPositionDescriptorAddressV1_3_0": "0xF4f5fddEe871D0A875F2935F88116c36E2bEBA6C",
    "descriptorProxyAddress": "0x8A6c47C58EAC6D029B9bbbd7FA818dB756d4D6cF",
    "nonfungibleTokenPositionManagerAddress": "0x0b1295Bdb04048e2e034183a11a12ddff96c7658",
    "v3MigratorAddress": "0x4a30aa29ebEf023506df78ffc56AD717958EC7eE",
    "v3StakerAddress": "0x7315CA9D17cE86F879C880c033B07126E79Dd4Fd",
    "quoterV2Address": "0xcfF44101989490C8DF9e48100bD48E2cc42F5E26",
    "quoterAddress": "0x996D0Ce5128c181b99736925e71D916603DDA420",
    "swapRouter02": "0x09ADF965ea872bBF5e7ac8e6e0D741E1c65DcCE9",
    "swapRouter": "0x09ADF965ea872bBF5e7ac8e6e0D741E1c65DcCE9"
}

export const CONTRACTS = {
    V3_CORE_FACTORY_ADDRESS: {
        137: mainnetContracts.v3CoreFactoryAddress,
        80001: testnetContracts.v3CoreFactoryAddress
    },
    MULTICALL2_ADDRESS: {
        137: mainnetContracts.multicall2Address,
        80001: testnetContracts.multicall2Address
    },
    PROXY_ADMIN_ADDRESS: {
        137: mainnetContracts.proxyAdminAddress,
        80001: testnetContracts.proxyAdminAddress
    },
    TICK_LENS_ADDRESS: {
        137: mainnetContracts.tickLensAddress,
        80001: testnetContracts.tickLensAddress
    },
    NFT_DESCRIPTOR_LIBRARY_ADDRESS_V1_3_0: {
        137: mainnetContracts.nftDescriptorLibraryAddressV1_3_0,
        80001: testnetContracts.nftDescriptorLibraryAddressV1_3_0
    },
    NONFUNGIBLE_TOKEN_POSITION_DESCRIPTOR_ADDRESS_V1_3_0: {
        137: mainnetContracts.nonfungibleTokenPositionDescriptorAddressV1_3_0,
        80001: testnetContracts.nonfungibleTokenPositionDescriptorAddressV1_3_0
    },
    DESCRIPTOR_PROXY_ADDRESS: {
        137: mainnetContracts.descriptorProxyAddress,
        80001: testnetContracts.descriptorProxyAddress
    },
    NONFUNGIBLE_TOKEN_POSITION_MANAGER_ADDRESS: {
        137: mainnetContracts.nonfungibleTokenPositionManagerAddress,
        80001: testnetContracts.nonfungibleTokenPositionManagerAddress
    },
    V3_MIGRATOR_ADDRESS: {
        137: mainnetContracts.v3MigratorAddress,
        80001: testnetContracts.v3MigratorAddress
    },
    V3_STAKER_ADDRESS: {
        137: mainnetContracts.v3StakerAddress,
        80001: testnetContracts.v3StakerAddress
    },
    QUOTER_V2_ADDRESS: {
        137: mainnetContracts.quoterV2Address,
        80001: testnetContracts.quoterV2Address
    },
    QUOTER_ADDRESS: {
        137: mainnetContracts.quoterAddress,
        80001: testnetContracts.quoterAddress
    },
    SWAP_ROUTER_02: {
        137: mainnetContracts.swapRouter02,
        80001: testnetContracts.swapRouter02
    },
    SWAP_ROUTER: {
        137: mainnetContracts.swapRouter,
        80001: testnetContracts.swapRouter
    },
    UNIVERSAL_ROUTER: {
        137: "0x91d5565d990952F34Cf0bc6d0bC5C31B6aBf243a", // 0x91d5565d990952F34Cf0bc6d0bC5C31B6aBf243a
        80001: testnetContracts.swapRouter02
    }
}

