import { useCallback, useEffect, useMemo, useState } from "react"
import PoolABI from "../../../abi/poolABI.json"
import { ethers } from "ethers"
import useActiveWeb3React from "../../../hooks/useWeb3"
import { useContract } from "../../../hooks/useContract"

export const useStakeInfo = ({address}) => {
    const { account } = useActiveWeb3React()
    const poolInstance = useContract( address, PoolABI )

    const [ staked, setStaked ] = useState(0)
    const [ totalStaked, setTotalStaked ] = useState(0)
    const [ generated, setGenerated ] = useState(0)
    const [ ocClaimed, setOcClaimed ] = useState(0)

    const [ ogGenerated, setOgGenerated ] = useState(0)
    const [ lockPeriod, setLockPeriod ] = useState(0)
    const [ isInLockPeriod, setIsInLockPeriod ] = useState(false)

    const fetchStaked = useCallback(async () => {
        if ( account ) {
            const staked = await poolInstance?.stakers( account )
            setStaked( parseFloat(ethers.utils.formatEther(staked.totalInvested)) )

            let ocReturned = parseFloat(ethers.utils.formatEther(staked.totalReturned))
            try {
                const generated = await poolInstance?.getGenerated( account )
                setGenerated( parseFloat(ethers.utils.formatEther(generated)) )
                
                const ogGenerated = await poolInstance?.getOGGenerated( account )
                setOgGenerated( parseFloat(ethers.utils.formatEther(ogGenerated)) )
            } catch(err) {

            }
            try {
                const uCount =  staked.unstakeCount
    
                if ( parseFloat(uCount) > 0 ) {
                    const uInfo = await poolInstance.unstakeRequests( account, uCount-1 )
                    const amount = parseFloat(ethers.utils.formatEther(uInfo.generated))
                    ocReturned += amount
                }
    
                setOcClaimed( ocReturned )
            } catch(err) {

            }
            try {
    
                const lockPeriod = await poolInstance?.poolInfo()
                const unstakePeriod = (lockPeriod.lockPeriod).add( staked.lastInvested )
    
                if ( unstakePeriod.toNumber() > (Date.now() / 1000) ) {
                    setIsInLockPeriod(true)
                } else {
                    setIsInLockPeriod(false)
                }
    
                setLockPeriod( unstakePeriod.toNumber() )
            } catch(err) {

            }

            try {
                const poolInfo = await poolInstance?.poolInfo()
                const totalStaked = parseFloat(ethers.utils.formatEther(poolInfo.totalStaked))

                setTotalStaked( totalStaked )
            } catch(err) {

            }
        }
    }, [poolInstance, account])

    useEffect(() => {
        fetchStaked()
        const timer = setInterval(() => fetchStaked(), 3500)
        return () => clearInterval(timer)
    }, [fetchStaked])

    return useMemo(() => {
        return {
            staked,
            generated,
            ogGenerated,
            lockPeriod,
            isInLockPeriod,
            ocClaimed,
            totalStaked
        }
    }, [staked, generated, ogGenerated, lockPeriod, isInLockPeriod, ocClaimed, totalStaked])
}